import {PageBasis} from "../components/page-basis";
import MainContainerWithMenu from "../components/main-container-with-menu";
import {BigTitle} from "../components/custom-text";
import React, {useEffect, useState} from "react";
import {ColumnFlexContainer} from "../components/styles/common-base.style";
import styled from "styled-components";
import {Sinister} from "../components/Sinister";
import {useDispatch, useSelector} from "react-redux";
import {
    BillContainer,
    BillListTitle, BillListTitleWrapper,
    BillWrapper,
    EmptyBills,
    EnsureBillLayout,
    ListBillHeader
} from "./styles/ListBill.styled";
import {CustomTextField, GlobalContainer} from "./styles/common.style";
import SearchIcon from "@mui/icons-material/Search";
import {getSinisters} from "../services/sinister.service";
import {useLocation, useNavigate} from "react-router-dom";
import CustomSnackBar from "../components/custom-snack-bar";
import useSnackBar from "../hooks/snack-bar.hook";
import {sinisterPage} from "../redux/connectedMenu.slice";
import {Button} from "../components/custom-buttons";
import {getBillsFiltered, getBillsWithoutSinister} from "../services/bill.service";
import {Bill} from "../components/Bill";


const SinisterContainer = styled(ColumnFlexContainer)`
  flex: 1;
  align-items: center;
`

const BillsContainer = styled(SinisterContainer)`
    padding-top: 50px;
`

const CentralContainer = styled(ColumnFlexContainer)`
  margin-bottom: 50px;
`


const MonthSinister = styled.p`
  align-self: self-start;
  margin-left: 20px;
  color: #13182B;
  font-weight: 300;
  font-size: 24px;
`

const ListSinister = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const {currentUser} = useSelector(state => state.user);

    const {
        fetchStatus, setFetchStatus,
        fetchMessage, setFetchMessage,
        severity, setSeverity
    } = useSnackBar();

    const [sinisters, setSinisters] = useState("");

    const [validBills, setValidBills] = useState([]);
    const [expiredBills, setExpiredBills] = useState([]);
    const [canDeclareSinister, setCanDeclareSinister] = useState(false);
    const [openBillsPanel, setOpenBillsPanel] = useState(false);

    useEffect(() => {
        async function fetchData() {
            dispatch(sinisterPage());
            const result = await getSinisters(dispatch, currentUser.insured.id);
            const prev = await location?.state?.prev || "/";
            if (result.hasOwnProperty("error")) {
                setFetchStatus(true);
                setFetchMessage(result.error);
                setSeverity("error");
            } else {
                setSinisters(result.sinistersFiltered);
                if(prev === "sinister-declaration") {
                    setFetchStatus(true);
                    setFetchMessage(location?.state?.message);
                    setSeverity("success");
                    navigate({
                        pathname: "/list-sinister"
                    });
                }
            }
        }
        fetchData();
        initBillFetch();
    }, []);
    
    const initBillFetch = async () => {
        const result = await getBillsWithoutSinister(currentUser?.insured?.id);
        if (result.hasOwnProperty("error")) {
            handleBillFetchError(result);
        } else {
            handleBillFetchSuccess(result);
            setOpenBillsPanel(false);
        }
    }
    
    const handleBillFetchSuccess = (result) => {
        setValidBills(result.validBills);
        setExpiredBills(result.expiredBills);
        if (result.validBills.length > 0 || result.expiredBills.length > 0) {
            setCanDeclareSinister(true);
        } else {
            setCanDeclareSinister(false);
        }
    }
    
    const handleBillFetchError = (result) => {
        setFetchStatus(true);
        setFetchMessage(result.error);
        setSeverity("error");
    }

    const handleSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchStatus(false);
    };
    
    const handleDeclareSinister = async () => {
        const bills = await getBillsWithoutSinister(currentUser?.insured?.id);
        if (bills.hasOwnProperty("error")) {
            await handleBillFetchError(bills);
        } else {
            await handleBillFetchSuccess(bills);
            setOpenBillsPanel(true);
            setCanDeclareSinister(false);
        }
    }

    return (
        <PageBasis>
            <GlobalContainer>
                <CustomSnackBar
                  open={fetchStatus}
                  handleSBClose={handleSBClose}
                  message={fetchMessage}
                  severity={severity}
                />
                <MainContainerWithMenu>
                    <ListBillHeader>
                        <BigTitle>Mes sinistres déclarés</BigTitle>
                        <CustomTextField
                            id="search"
                            size="small"
                            placeholder="Recherchez"
                            style={{width: "60%"}}
                            InputProps={{
                                endAdornment: <SearchIcon/>
                            }}
                        />
                    </ListBillHeader>
                    {canDeclareSinister &&
                      <EnsureBillLayout>
                        <BigTitle></BigTitle>
                        <Button width='230px' onClick={handleDeclareSinister}>
                            Déclarer un sinistre
                        </Button>
                        <BigTitle></BigTitle>
                      </EnsureBillLayout>
                    }
                    
                    {
                        (Object.keys(sinisters).length === 0) &&
                        <EmptyBills>
                            <BillListTitle>Vous n'avez pas de sinistres déclarés</BillListTitle>
                        </EmptyBills>
                    }
                    <CentralContainer>
                        {openBillsPanel &&
                            <>
                                  <BillsContainer>
                                      {
                                          (validBills.length !== 0) &&
                                          <>
                                              <BillListTitleWrapper>
                                                  <BillListTitle>Facture arrivant à échéance</BillListTitle>
                                              </BillListTitleWrapper>
                                              {
                                                  validBills.map((bill) =>
                                                      <BillWrapper key={bill.id}>
                                                          <Bill key={bill.id} bill={bill}/>
                                                      </BillWrapper>
                                                  )
                                              }
                                          </>
                                      }
                                  </BillsContainer>
                                <BillContainer>
                                    {
                                        (expiredBills.length !== 0) &&
                                        <>
                                            <BillListTitleWrapper>
                                                <BillListTitle>Échéance depassée</BillListTitle>
                                            </BillListTitleWrapper>
                                            {
                                                expiredBills.map((bill) =>
                                                    <BillWrapper key={bill.id}>
                                                        <Bill key={bill.id} bill={bill}/>
                                                    </BillWrapper>
                                                )
                                            }
                                        </>
                                    }
                                </BillContainer>
                            </>
                        }
                        {!openBillsPanel && Object.keys(sinisters).map((month) => {
                            return (
                                <SinisterContainer key={month}>
                                    <MonthSinister>{month}</MonthSinister>
                                    {sinisters[month].map(sinister => (
                                        <Sinister marginVertical={"10px"}
                                                  name={sinister.firstnameInterlocutor+' '+sinister.lastnameInterlocutor}
                                                  phone={sinister.telInterlocutor}
                                                  billFileName={sinister.billFileName}
                                                  bill={sinister.bill}
                                                  key={sinister.id}
                                        />
                                    ))}
                            </SinisterContainer>)
                        })}
                    </CentralContainer>
                </MainContainerWithMenu>
            </GlobalContainer>
        </PageBasis>
    )
}

export default ListSinister;
