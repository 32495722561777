import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import {AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

const AccordionContainer = styled.div`
  border-radius: 8px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: solid 3px #${(props) => props.color};
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 0 0 #${(props) => props.color};
`

const Icon = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
`


const Text = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 10px;
`

const TextSpan = styled.div`
  text-align: center;
`

export const AccordionLeezay = (props) => {
    return (
        <Accordion style={{border:"solid 3px #0652DD", boxShadow: "5px 5px 0 0 #0652DD", borderRadius: 8}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{color: "#0652DD"}}/>}
                aria-controls="panel1a-content"
                id="panel1a-header">
                {/*<Typography>{props.summary}</Typography>*/}
                <TextSpan >{props.summary}</TextSpan>
            </AccordionSummary>
            <AccordionDetails>
                {/*<Typography>*/}
                {/*    {props.details}*/}
                {/*</Typography>*/}
                <TextSpan>
                    {props.details}
                </TextSpan>
            </AccordionDetails>
        </Accordion>
    )
}
