import {PageBasis} from "../components/page-basis";
import MainContainerWithMenu from "../components/main-container-with-menu";
import {
    BottomForm,
    HeaderTitle,
    InformationContainer, InformationTitle, LeftForm, RightForm,
    SinisterDeclarationHeader,
    TopFormContainer,
} from "./styles/SinisterDeclaration.style";
import {
    WrapperFormItem,
    WrapperUploadItem,
    UploadButton,
    WrapperFileName,
    SubmitButtonContainer,
    ArrowContainer,
    ArrowSpan,
    CustomTextField, ErrorSpan, GlobalContainer
} from "./styles/common.style";
import {StandardText} from "../components/custom-text";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {Button} from "../components/custom-buttons";
import {ProfileLabel} from "./styles/Profile.style";
import {RowFlexContainer} from "../components/styles/common-base.style";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {sinisterPage} from "../redux/connectedMenu.slice";
import {useLocation, useNavigate} from "react-router-dom";
import {declareSinister} from "../services/sinister.service";
import CustomSnackBar from "../components/custom-snack-bar";
import React, {useEffect} from "react";
import useSnackBar from "../hooks/snack-bar.hook";


const SinisterDeclaration = () => {

    const location = useLocation();
    const {bill} = location.state;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(sinisterPage());
    }, []);

    const {
        fetchStatus, setFetchStatus,
        fetchMessage, setFetchMessage,
        severity, setSeverity
    } = useSnackBar();


    const validationSchema = Yup.object({
        firstnameInterlocutor: Yup.string()
            .required('Obligatoire'),
        lastnameInterlocutor: Yup.string()
            .required('Obligatoire'),
        telInterlocutor: Yup.string()
            .required('Obligatoire'),
        mailInterlocutor: Yup.string()
            .email('Entrez un mail valid')
            .required('Obligatoire'),
        billCopy: Yup.string()
            .required('Obligatoire'),
    });

    const formik = useFormik({
        initialValues: {
            firstnameInterlocutor: '',
            lastnameInterlocutor: '',
            telInterlocutor: '',
            mailInterlocutor: '',
            billCopy: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values["billId"] = bill.id;
            const result = await declareSinister(values);
            if(result.hasOwnProperty('error')) {
                setFetchStatus(true);
                setFetchMessage(result.error);
                setSeverity("error");
            } else {
                navigate("/list-sinister", { state: {prev: "sinister-declaration", message: result.success}});
            }
        }
    });

    const handleSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchStatus(false);
    };

    return (
        <PageBasis>
            <GlobalContainer>
                <CustomSnackBar
                  open={fetchStatus}
                  handleSBClose={handleSBClose}
                  message={fetchMessage}
                  severity={severity}
                />
                <MainContainerWithMenu>
                    <SinisterDeclarationHeader>
                        <ArrowContainer>
                            <ArrowSpan>&#8592;</ArrowSpan>
                        </ArrowContainer>
                        <HeaderTitle>Déclarez un sinistre</HeaderTitle>
                    </SinisterDeclarationHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <InformationContainer>
                            <InformationTitle fontSize={24}>Interlocuteur</InformationTitle>
                            <TopFormContainer>
                                <LeftForm>
                                    <WrapperFormItem>
                                        <ProfileLabel>Prénom</ProfileLabel>
                                        <CustomTextField
                                            id="firstnameInterlocutor"
                                            size="small"
                                            placeholder="Entrez le nom de l'interlocuteur"
                                            onChange={formik.handleChange}
                                            value={formik.values.firstnameInterlocutor}
                                            error={formik.touched.firstnameInterlocutor && Boolean(formik.errors.firstnameInterlocutor)}
                                            helperText={formik.touched.firstnameInterlocutor && formik.errors.firstnameInterlocutor}
                                        />
                                    </WrapperFormItem>
                                    <WrapperFormItem>
                                        <ProfileLabel>Numéro</ProfileLabel>
                                        <CustomTextField
                                            id="telInterlocutor"
                                            size="small"
                                            placeholder="Entrez le numéro de l'interlocuteur"
                                            onChange={formik.handleChange}
                                            value={formik.values.telInterlocutor}
                                            error={formik.touched.telInterlocutor && Boolean(formik.errors.telInterlocutor)}
                                            helperText={formik.touched.telInterlocutor && formik.errors.telInterlocutor}
                                        />
                                    </WrapperFormItem>
                                </LeftForm>
                                <RightForm>
                                    <WrapperFormItem>
                                        <ProfileLabel>Nom</ProfileLabel>
                                        <CustomTextField
                                            id="lastnameInterlocutor"
                                            size="small"
                                            placeholder="Entrez le nom de l'interlocuteur"
                                            onChange={formik.handleChange}
                                            value={formik.values.lastnameInterlocutor}
                                            error={formik.touched.lastnameInterlocutor && Boolean(formik.errors.lastnameInterlocutor)}
                                            helperText={formik.touched.lastnameInterlocutor && formik.errors.lastnameInterlocutor}
                                        />
                                    </WrapperFormItem>
                                    <WrapperFormItem>
                                        <ProfileLabel>Mail</ProfileLabel>
                                        <CustomTextField
                                            id="mailInterlocutor"
                                            size="small"
                                            placeholder="Entrez le mail de l'interlocuteur"
                                            onChange={formik.handleChange}
                                            value={formik.values.mailInterlocutor}
                                            error={formik.touched.mailInterlocutor && Boolean(formik.errors.mailInterlocutor)}
                                            helperText={formik.touched.mailInterlocutor && formik.errors.mailInterlocutor}
                                        />
                                    </WrapperFormItem>
                                    {/*<WrapperFormItem>*/}
                                    {/*    <ProfileLabel>Avoir potentiel sur la facture</ProfileLabel>*/}
                                    {/*    <CustomTextField*/}
                                    {/*        id="credit"*/}
                                    {/*        size="small"*/}
                                    {/*        placeholder="Entrez le montant de l'avoir"*/}
                                    {/*        InputProps={{*/}
                                    {/*            endAdornment: <InputAdornment position="end">€</InputAdornment>,*/}
                                    {/*        }}*/}
                                    {/*        onChange={formik.handleChange}*/}
                                    {/*        value={formik.values.credit}*/}
                                    {/*    />*/}
                                    {/*</WrapperFormItem>*/}
                                </RightForm>
                            </TopFormContainer>
                        </InformationContainer>
                        <InformationContainer>
                            <InformationTitle fontSize={24}>Documents à fournir</InformationTitle>
                            <TopFormContainer>
                                <BottomForm>
                                    <>
                                        <WrapperUploadItem>
                                            <ProfileLabel>Copie de la facture</ProfileLabel>
                                            <RowFlexContainer>
                                                <UploadButton width="120px" >
                                                    Enregistrer
                                                    <input
                                                        id="billCopy"
                                                        name="billCopy"
                                                        type="file"
                                                        onChange={(e) =>
                                                            formik.setFieldValue('billCopy', e.currentTarget.files[0])
                                                        }
                                                        style={{display: "none"}}
                                                    />
                                                    <FileDownloadOutlinedIcon sx={{fontSize: 25}} />
                                                </UploadButton>
                                                <WrapperFileName>
                                                    <StandardText fontSize={18}>{formik.values.billCopy.name}</StandardText>
                                                </WrapperFileName>
                                            </RowFlexContainer>
                                            <ErrorSpan>{formik.errors.billCopy}</ErrorSpan>
                                        </WrapperUploadItem>
                                    </>
                                </BottomForm>
                                {/*<RightForm>*/}
                                {/*    <WrapperUploadItem>*/}
                                {/*        <ProfileLabel>Copie de l'avoir</ProfileLabel>*/}
                                {/*        <RowFlexContainer>*/}
                                {/*            <UploadButton width="40%" >*/}
                                {/*                Modifier*/}
                                {/*                <input*/}
                                {/*                    id="creditCopy"*/}
                                {/*                    name="creditCopy"*/}
                                {/*                    type="file"*/}
                                {/*                    onChange={(e) =>*/}
                                {/*                        formik.setFieldValue('creditCopy', e.currentTarget.files[0])*/}
                                {/*                    }*/}
                                {/*                    style={{display: "none"}}*/}
                                {/*                />*/}
                                {/*                <FileDownloadOutlinedIcon sx={{fontSize: 25}} />*/}
                                {/*            </UploadButton>*/}
                                {/*            <WrapperFileName>*/}
                                {/*                <StandardText fontSize={18}>{formik.values.creditCopy.name}</StandardText>*/}
                                {/*            </WrapperFileName>*/}
                                {/*        </RowFlexContainer>*/}
                                {/*    </WrapperUploadItem>*/}
                                {/*</RightForm>*/}
                            </TopFormContainer>
                        </InformationContainer>

                        <SubmitButtonContainer>
                            <Button type="submit" width="25%">Déclarez un sinistre</Button>
                        </SubmitButtonContainer>
                    </form>
                </MainContainerWithMenu>
            </GlobalContainer>
        </PageBasis>
    )
}

export default SinisterDeclaration;
