import styled from "styled-components";

export const Button = styled.button`
  width: ${(props) => props.width};
  height: 40px;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "#0652DD"};
  border-radius: 10px;
  color: ${(props) => props.backgroundColor ? "black" : "white"};
  font-size: 16px;
  font-weight: 600;
  border: solid 2px black;
  border-bottom-width: 4px;
  border-right-width: 4px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`

export const IconButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const RegularButton = (props) => {
    return (
        <Button width={props.width}>{props.children}</Button>
    )
}

export const ButtonWithIcon = ({icon, width, backgroundColor, children}) => {
    return (
        <IconButton width={width} backgroundColor={backgroundColor}>{children}
            {icon}
        </IconButton>
    )
}
