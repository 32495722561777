import moment from "moment/moment";

export function dateToDateTime(date) {
    return moment(date);
}

export function dateToFrenchFormat(date) {
    return moment(date).format('DD/MM/YYYY');
}

export function dateFormatForMuiDatePicker(date) {
    return moment(date).format('YYYY-MM-DD');
}

export function addOneDayToDate(date) {
    const newDate = moment(date).add(1, 'd');
    return newDate.format();
}

export function checkNextGreaterThanBefore(before, next) {
    return moment(next).isAfter(before);
}

export function dateToMonthYear(date) {
    return moment(date).format('MMMM YYYY');
}

export function numberOfDaysBetweenTwoDates(date1, date2) {
    const a = moment(date1);
    const b = moment(date2);
    return (b.diff(a, 'days')+1);
}
