import styled from "styled-components";


export const CardContainer = styled.div`
  border-radius: 8px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: solid 3px #${(props) => props.color};
  display: flex;
  background-color: #${(props) => props.bgColor};
  flex-direction: column;
  justify-content: center;
  box-shadow: 5px 5px 0 0 #${(props) => props.color};
`
