import styled from "styled-components";
import {MediumTitle, Title} from "./custom-text";

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 300px;
  background-color: #FBFDFF;
`

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 55%;
  padding-top: 5%;
`

const Left = styled.div`
  flex: 1;
`

const Center = styled.div`
  flex: 1;
`

const Right = styled.div`
  flex: 1;
`

const TitleSpan = styled(MediumTitle)`
  margin: 8px 8px 10px 5px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemsSpan = styled.span`
  margin: 5px;
`

const ABOUT = ["Leezay, c'est quoi ?", "Comment ça marche ?", "Les fonctionnalités de Leezay", "Nos partenaires"]
const CONTACT = ["LinkedIn", "Email"]
const RESOURCE = ["Foire aux questions", "Politique de confidentialité", "Conditions générales d'utilisation"]
export const Footer = () => {
    return (
        <Container>
            <MainContainer>
                <Left>
                    <List>
                        <TitleSpan >A propos</TitleSpan>
                        {ABOUT.map((item)=>{
                            return <ItemsSpan key={item}>{item}</ItemsSpan>
                        })}
                    </List>
                </Left>
                <Center>
                    <List>
                        <TitleSpan >Nous contacter</TitleSpan>
                        {CONTACT.map((item)=>{
                            return <ItemsSpan key={item}>{item}</ItemsSpan>
                        })}
                    </List>
                </Center>
                <Right>
                    <List>
                        <TitleSpan>Ressources</TitleSpan>
                        {RESOURCE.map((item)=>{
                            return <ItemsSpan key={item}>{item}</ItemsSpan>
                        })}
                    </List>
                </Right>
            </MainContainer>
        </Container>
    )
}
