import {PageBasis} from "../components/page-basis";
import MainContainerWithMenu from "../components/main-container-with-menu";
import {
    ArrowWrapper, CheckBoxWrapper,
    HeaderTitle,
    InformationContainer, InformationTitle, LeftForm, RightForm,
    SinisterDeclarationHeader,
    TopFormContainer,
    UploadContainer
} from "./styles/SinisterDeclaration.style";
import {
    WrapperFormItem, SubmitButtonContainer, ArrowContainer, ArrowSpan, CustomTextField, GlobalContainer
} from "./styles/common.style";
import {CardContainer} from "../components/containers";
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import {Button} from "../components/custom-buttons";
import {ProfileLabel} from "./styles/Profile.style";
import * as Yup from "yup";
import {useFormik} from "formik";
import {StandardText} from "../components/custom-text";
import {useDispatch, useSelector} from "react-redux";
import {ensureBills} from "../services/bill.service";
import {useNavigate} from "react-router-dom";
import {updateNumberOfBillsInBasket} from "../redux/login.slice";


const Checkout = () => {

    const {currentUser} = useSelector(state => state.user)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = Yup.object({
        address: Yup.string()
            .required('Obligatoire'),
        posteCode: Yup.string()
            .required('Obligatoire'),
        city: Yup.string()
            .required('Obligatoire'),
        saveAddress: Yup.bool()
            .default(false),
        cardNumber: Yup.string()
            .required('Obligatoire'),
        cardName: Yup.string()
            .required('Obligatoire'),
        cardDate: Yup.string()
            .required('Obligatoire'),
        cardCryptogram: Yup.string()
            .required('Obligatoire'),
    });

    const formik = useFormik({
        initialValues: {
            address: '20 rue colin',
            posteCode: '69100',
            city: 'Villeurbanne',
            saveAddress: false,
            cardNumber: '12345678912345',
            cardName: 'Paul Kepler',
            cardDate: '10/25',
            cardCryptogram: '587',
            saveCard: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log(values);
            await ensureBills(dispatch, currentUser.insured.id)
            navigate("/", {replace: true})
        }
    });

    return (
        <PageBasis>
            <GlobalContainer>
                <MainContainerWithMenu>
                    <SinisterDeclarationHeader>
                        <ArrowContainer>
                            <ArrowSpan>&#8592;</ArrowSpan>
                        </ArrowContainer>
                        <HeaderTitle>Paiement</HeaderTitle>
                    </SinisterDeclarationHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <InformationContainer>
                            <InformationTitle fontSize={24}>Informations complémentaires</InformationTitle>
                            <TopFormContainer>
                                <LeftForm>
                                    <WrapperFormItem>
                                        <ProfileLabel>Adresse</ProfileLabel>
                                        <CustomTextField
                                            id="address"
                                            size="small"
                                            placeholder="Entrez l'adresse"
                                            onChange={formik.handleChange}
                                            value={formik.values.address}
                                            error={formik.touched.address && Boolean(formik.errors.address)}
                                            helperText={formik.touched.address && formik.errors.address}
                                        />
                                    </WrapperFormItem>
                                    <WrapperFormItem>
                                        <ProfileLabel>Code Postal</ProfileLabel>
                                        <CustomTextField
                                            id="posteCode"
                                            size="small"
                                            placeholder="XXXXX"
                                            onChange={formik.handleChange}
                                            value={formik.values.posteCode}
                                            error={formik.touched.posteCode && Boolean(formik.errors.posteCode)}
                                            helperText={formik.touched.posteCode && formik.errors.posteCode}
                                        />
                                    </WrapperFormItem>
                                </LeftForm>
                                <RightForm>
                                    <WrapperFormItem>
                                        <ProfileLabel>Ville</ProfileLabel>
                                        <CustomTextField
                                            id="city"
                                            size="small"
                                            placeholder="Entrez la ville"
                                            onChange={formik.handleChange}
                                            value={formik.values.city}
                                            error={formik.touched.city && Boolean(formik.errors.city)}
                                            helperText={formik.touched.city && formik.errors.city}
                                        />
                                    </WrapperFormItem>
                                </RightForm>
                            </TopFormContainer>
                            <CheckBoxWrapper>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="saveAddress"
                                            checked={formik.values.saveAddress}
                                            onChange={formik.handleChange}
                                        />
                                    }
                                    label={<StandardText>Enregistrez mon addresse pour le prochain paiement</StandardText>}
                                />
                            </CheckBoxWrapper>
                        </InformationContainer>
                        <InformationContainer>
                            <InformationTitle fontSize={24}>Informations complémentaires</InformationTitle>
                            <TopFormContainer>
                                <LeftForm>
                                    <WrapperFormItem>
                                        <ProfileLabel>Numéro de la carte</ProfileLabel>
                                        <CustomTextField
                                            id="cardNumber"
                                            size="small"
                                            placeholder="Numéro de carte"
                                            onInput = {(e) =>{
                                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,16)
                                            }}
                                            onChange={formik.handleChange}
                                            value={formik.values.cardNumber}
                                            error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
                                            helperText={formik.touched.cardNumber && formik.errors.cardNumber}
                                        />
                                    </WrapperFormItem>
                                    <WrapperFormItem>
                                        <ProfileLabel>Date d'expiration</ProfileLabel>
                                        <CustomTextField
                                            id="cardDate"
                                            size="small"
                                            placeholder="XX/XX"
                                            onChange={formik.handleChange}
                                            value={formik.values.cardDate}
                                            error={formik.touched.cardDate && Boolean(formik.errors.cardDate)}
                                            helperText={formik.touched.cardDate && formik.errors.cardDate}
                                        />
                                    </WrapperFormItem>
                                </LeftForm>
                                <RightForm>
                                    <WrapperFormItem>
                                        <ProfileLabel>Nom sur la carte</ProfileLabel>
                                        <CustomTextField
                                            id="cardName"
                                            size="small"
                                            placeholder="Nom sur la carte"
                                            onChange={formik.handleChange}
                                            value={formik.values.cardName}
                                            error={formik.touched.cardName && Boolean(formik.errors.cardName)}
                                            helperText={formik.touched.cardName && formik.errors.cardName}
                                        />
                                    </WrapperFormItem>
                                    <WrapperFormItem>
                                        <ProfileLabel>Cryptogram</ProfileLabel>
                                        <CustomTextField
                                            id="cardCryptogram"
                                            size="small"
                                            placeholder="XXX"
                                            onInput = {(e) =>{
                                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                            }}
                                            onChange={formik.handleChange}
                                            value={formik.values.cardCryptogram}
                                            error={formik.touched.cardCryptogram && Boolean(formik.errors.cardCryptogram)}
                                            helperText={formik.touched.cardCryptogram && formik.errors.cardCryptogram}
                                        />
                                    </WrapperFormItem>
                                </RightForm>
                            </TopFormContainer>
                        </InformationContainer>
                        <SubmitButtonContainer>
                            <Button type="Submit" width="25%">Payer</Button>
                        </SubmitButtonContainer>
                    </form>
                </MainContainerWithMenu>
            </GlobalContainer>
        </PageBasis>
    )
}

export default Checkout;
