import {loginFail, loginStart, loginSuccess} from "./login.slice";
import {publicRequest} from "../services/requests";
import {registerFailure, registerStart, registerSuccess} from "./register.slice";
import {billsFetchFailure, billsFetchStart, billsFetchSuccess} from "./bills.slice";
import moment from "moment";

export const login = async (dispatch, user) => {
    dispatch(loginStart());
    try {
        const resToken = await publicRequest.post("/users/login", user);
        const resUser = await publicRequest.get(`person?filter[where][email]=${user.email}&filter[include][][relation]=insured`);
        let numberOfBillsInBasket = 0;
        if(resUser.data[0].insured) {
            const resCount = await publicRequest.get(`/bills/count?where[isInBasket]=true&where[insuredId]=${resUser.data[0].insured.id}`)
            numberOfBillsInBasket = await resCount.data.count;
        }
        dispatch(loginSuccess({"token": resToken.data, "user": resUser.data[0], "numberOfBillsInBasket": numberOfBillsInBasket}))
        return true;
    } catch (err) {
        dispatch(loginFail());
        return false;
    }
}

export const registerUser = async (dispatch, {firstName, lastName, phone, email, password}) => {
    try {
        const requiredUser = {firstName, lastName, phone, email}
        const requiredCred = {email, password}

        const resCred =  await publicRequest.post("/signup", requiredCred);
        if(resCred) {
            const resUser = await publicRequest.post("/person", requiredUser);
            dispatch(registerSuccess(resUser.data))
        }
    } catch (err) {
        throw new Error(err.response.data.error.message)
    }
}

export const fetchBills = async (dispatch) => {
    dispatch(billsFetchStart());
    try {
        const validBills = [];
        const expiredBills = [];
        const bills = await publicRequest.get("/bills?filter[where][email]= &filter[include][][relation]=sinister")

        bills.data.map((bill) => {
            const endDate = new Date(bill.endDate).getTime();
            const issuedDate = new Date(bill.issuedDate).getTime();
            const now = Date.now();
            const diff = endDate - now;
            if (diff <= 0) {
                bill["endDate"] = moment(bill.endDate).format('DD/MM/YYYY');
                expiredBills.push(bill);
            } else {
                const duration = Math.round(((endDate - issuedDate) / (1000 * 3600 * 24)));
                const issuedToNow = Math.round(((now - issuedDate) / (1000 * 3600 * 24)));
                bill["daysRemaining"] = Math.round((diff / (1000 * 3600 * 24)));
                bill["progression"] = Math.round(((issuedToNow * 100) / duration));
                validBills.push(bill);
            }
        });
        dispatch(billsFetchSuccess({"validBills": validBills, "expiredBills": expiredBills}))
    } catch (err) {
        dispatch(billsFetchFailure());
    }
}
