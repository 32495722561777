import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
// import {Button} from "./custom-buttons";

export default function AlertDialog(props) {

    const handleCancel = () => {
        props.handleCancel();
    };

    const handleValidate = () => {
        props.handleValidate();
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.text}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCancel} sx={{marginRight: '10px'}} variant="outlined">Annuler</Button>
                    <Button onClick={handleValidate} variant="contained" autoFocus>
                        Continuer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
