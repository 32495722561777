import {useSelector} from "react-redux";
import {Outlet, Navigate} from "react-router-dom";

const RequireAuth = ({allowedRoles}) => {
  const user = useSelector(state=>state.user.currentUser);

  const userHasRequiredRole = !!(user && allowedRoles.includes(user?.isAdmin ? "admin" : "user"));

  return userHasRequiredRole
      ? <Outlet />
      : user
          ? <Navigate to="/" replace />
          : <Navigate to="/login" replace />

}

export default RequireAuth;
