import styled from "styled-components";
import {MediumTitle, Title} from "../../components/custom-text";
import {ColumnFlexContainer, RowFlexContainer} from "../../components/styles/common-base.style";

export const MainContainer = styled(ColumnFlexContainer)`
  width: 60%;
  height: 100%;
  border-radius: 10px;
  border: solid 2px black;
  box-shadow: 5px 5px 0 0 #F4FC00;
  align-self: center;
  background-color: white;
`

export const Top = styled(RowFlexContainer)`
  flex: 1;
  margin-left: 5%;
`

export const MenuTitle = styled(Title)`
  :hover {
    opacity: 0.5;
  }
`

export const ErrorMessage = styled(Title)`
  text-align: center;
`

export const TitleContainer = styled.div`
  width: 200px;
  height: 40px;
  margin: 25px 20px 0 0;
  cursor: pointer;
  //background-color: #F4FC00;
`

export const ResetPasswordTitleContainer = styled.div`
  margin: 25px 0 0 5%;
  justify-content: center;
  align-items: center;
`

export const Body = styled.div`
  flex: 3;
  display: flex;
  justify-content: space-around;
`

export const BodyLeft = styled.div`
  width: 40%;
  height: 100%;
`

export const BodyRight = styled.div`
  width: 40%;
  height: 100%;
`

export const FormItemContainer = styled(ColumnFlexContainer)`
  width: 100%;
  margin: 0 0 25px 0;
`

export const FormButtonContainer = styled.div`
  width: 100%;
  margin: 25px 0 25px 0;
  display: flex;
  justify-content: center;
`

export const InputLabel = styled(MediumTitle)`
  margin-bottom: 5px;
`

export const LoginBody = styled(ColumnFlexContainer)`
    margin: 5%;
`

export const ResetPasswordBody = styled(ColumnFlexContainer)`
    margin: 2% 5% 4% 5%;
`

export const ResultContainer = styled(ColumnFlexContainer)`
  margin: 3% 5% 3% 5%;
  justify-content: center;
  align-items: center;
`

export const CheckboxLabelWrapper = styled(RowFlexContainer)`
  justify-content: center;
  align-items: center;
`
