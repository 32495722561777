import {createSlice} from "@reduxjs/toolkit";

const connectedMenuSlice = createSlice({
    name: "connectedMenu",
    initialState: {
        isBill: false,
        isSinister: false,
        isProfile: false
    },
    reducers: {
        billPage: (state) => {
            state.isBill = true;
            state.isSinister = false;
            state.isProfile = false;
        },
        sinisterPage: (state) => {
            state.isBill = false;
            state.isSinister = true;
            state.isProfile = false;
        },
        profilePage: (state) => {
            state.isBill = false;
            state.isSinister = false;
            state.isProfile = true;
        },
    }
})

export const { billPage, sinisterPage, profilePage } = connectedMenuSlice.actions;
export default connectedMenuSlice.reducer;
