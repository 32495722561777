import {createSlice} from "@reduxjs/toolkit";


const billsSlice = createSlice({
    name: "bills",
    initialState: {
        isFetching: false,
        success: false,
        error: false,
        validBills: [],
        expiredBills: [],
    },
    reducers: {
        billsFetchStart: (state) => {
            state.isFetching = true;
            state.error = false;
            state.success = false;
        },
        billsFetchSuccess: (state,{payload}) => {
            state.isFetching = false;
            state.success = true;
            state.error = false;
            state.validBills = payload.validBills;
            state.expiredBills = payload.expiredBills;
        },
        billsFetchFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.success = false;
        },
        billsReset: (state) => {
            state.isFetching = false;
            state.success = false;
            state.error = false;
            state.validBills = [];
            state.expiredBills = [];
        },
    }
})

export const { billsFetchStart, billsFetchSuccess, billsFetchFailure, billsReset } = billsSlice.actions;
export default billsSlice.reducer;
