import {PageBasis} from "../components/page-basis";
import {BigTitle} from "../components/custom-text";
import {
    ContactContainer,
    MotionContainer,
    QuestionsContainer,
    RepeatedText,
    WrapperQuestions,
    WrapperTitle,
    TextScroll,
    WrapperContactText,
    TextCustom,
    BaseContainer,
    FormButtonContainer,
    FormContainer,
    FormLeftContainer,
    FormRightContainer,
    InputLabel,
    WrappedFormItem,
    WrapperForm,
    DescriptionText, PageTitle
} from "./styles/Contact-FAQ.style";
import {AccordionLeezay} from "../components/accordion";
import {RegularButton} from "../components/custom-buttons";
import {Link} from "react-router-dom";
import {CustomTextField, GlobalContainer} from "./styles/common.style";

const accordionContentArray = [
    {
        id: 1,
        title: "Test summary",
        details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        id: 2,
        title: "Test summary",
        details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        id: 3,
        title: "Test summary",
        details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    },
    {
        id: 4,
        title: "Test summary",
        details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    }
]

const Contact = () => {

    const arrayOfTextScroll = new Array(20).fill(0);

    return (
        <PageBasis>
            <GlobalContainer>
                <BaseContainer>
                    <WrapperTitle>
                        <PageTitle>Vous avez une question ?</PageTitle>
                        <DescriptionText>N'hésitez pas à nous en faire part grâce au formulaire ci-dessous ! </DescriptionText>
                    </WrapperTitle>

                    <WrapperForm>
                        <FormContainer>
                            <FormLeftContainer>
                                <WrappedFormItem>
                                    <InputLabel>Sujet</InputLabel>
                                    <CustomTextField
                                        id="subject"
                                        size="small"
                                        placeholder="Entrez le sujet de votre demande"
                                    />
                                </WrappedFormItem>

                                <WrappedFormItem>
                                    <InputLabel>Nom complet</InputLabel>
                                    <CustomTextField
                                        id="complete-name"
                                        size="small"
                                        placeholder="Entrez votre nom en entier"
                                    />
                                </WrappedFormItem>

                                <WrappedFormItem>
                                    <InputLabel>Mail</InputLabel>
                                    <CustomTextField
                                        id="email"
                                        size="small"
                                        placeholder="Entrez votre adresse mail"
                                    />
                                </WrappedFormItem>

                            </FormLeftContainer>
                            <FormRightContainer>
                                <WrappedFormItem>
                                    <InputLabel>Message</InputLabel>
                                    <CustomTextField
                                        id="message"
                                        placeholder="Message"
                                        multiline
                                        rows={8}
                                    />
                                </WrappedFormItem>
                                <FormButtonContainer>
                                    <RegularButton width='80%'>Envoyer</RegularButton>
                                </FormButtonContainer>

                            </FormRightContainer>
                        </FormContainer>
                    </WrapperForm>


                </BaseContainer>
                <>
                    <RepeatedText>
                        <MotionContainer>
                            {
                                arrayOfTextScroll.map(()=> {
                                    return <TextScroll>&#x1F310;  &nbsp;&nbsp;Une question ?</TextScroll>
                                })
                            }
                        </MotionContainer>
                    </RepeatedText>

                    <ContactContainer>
                        <WrapperContactText>
                            <BigTitle>Et si vous alliez voir dans la foire aux question</BigTitle>
                            <TextCustom>Nous avons repondu à un certain nombre de questions là-bas</TextCustom>
                        </WrapperContactText>
                        <Link to={'/faq'}>
                            <RegularButton width={'200px'}>Foire aux questions</RegularButton>
                        </Link>
                    </ContactContainer>

                    <RepeatedText>
                        <MotionContainer>
                            {
                                arrayOfTextScroll.map(()=> {
                                    return <TextScroll>&#x1F310;  &nbsp;&nbsp;Une question ?</TextScroll>
                                })
                            }
                        </MotionContainer>
                    </RepeatedText>
                </>

                <BaseContainer>
                    <WrapperQuestions>
                        <BigTitle>Des questions que tout le monde se pose</BigTitle>
                        <QuestionsContainer>
                            {
                                accordionContentArray.map((item, index)=>{
                                    if(index<3) {
                                        return (
                                            <AccordionLeezay
                                                key={item.id}
                                                summary={item.title}
                                                details={item.details} />
                                        )
                                    }
                                    return null;
                                })
                            }
                        </QuestionsContainer>
                    </WrapperQuestions>
                </BaseContainer>
            </GlobalContainer>

        </PageBasis>
    )
}

export default Contact;
