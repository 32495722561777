import {PageBasis} from "../components/page-basis";
import {Button} from "../components/custom-buttons";
import {
    Body, BodyLeft, BodyRight, CheckboxLabelWrapper, FormButtonContainer, LoginBody,
    MainContainer, MenuTitle, TitleContainer, Top,
} from "./styles/Login.style"
import {CustomTextField, GlobalContainer, InfoContainer, WrapperFormItem} from "./styles/common.style";
import {ProfileLabel} from "./styles/Profile.style";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useEffect, useState} from "react";
import {login, registerUser} from "../redux/apiCalls";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, FormControlLabel, FormHelperText, InputAdornment} from "@mui/material";
import {StandardText} from "../components/custom-text";
import {CheckBoxWrapper} from "./styles/SinisterDeclaration.style";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";

import InfoIcon from '@mui/icons-material/Info';
import useSnackBar from "../hooks/snack-bar.hook";
import CustomSnackBar from "../components/custom-snack-bar";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {

    const user = useSelector(state=>state.user.currentUser);
    // GET data from evaluate bill page
    const { state } = useLocation();

    const [isLogin, setIsLogin] = useState(true);
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = React.useState(false);
    const [showLoginPassword, setShowLoginPassword] = React.useState(false);

    const [loginSuccess, setLoginSuccess] = useState(false);
    const [loginFail, setLoginFail] = useState(false);

    const navigate = useNavigate();

    const [SBOpen, setSBOpen] = useState(false);
    const [SBMessage, setSBMessage] = useState("");
    // handle request feed back
    const {
        fetchStatus, setFetchStatus,
        fetchMessage, setFetchMessage,
        severity, setSeverity
    } = useSnackBar();

    useEffect(() => {
        if (user) {
            navigate("/", {replace: true})
        }
        if (state) {
            if(state.hasOwnProperty("resetPasswordSuccessMsg")) {
                setSBMessage(state.resetPasswordSuccessMsg);
                setSBOpen(true);
            }
            if(state.hasOwnProperty("updatePwdSuccessMsg")) {
                setSBMessage(state.updatePwdSuccessMsg);
                setSBOpen(true);
            }
            navigate({
                pathname: "/login"
            });
        }

    });

    const handleSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSBOpen(false);
    };

    const registerValidationSchema = Yup.object({
        firstName: Yup.string()
            .required('Obligatoire'),
        lastName: Yup.string()
            .required('Obligatoire'),
        phone: Yup.number()
            .typeError('Veuillez saisir un nombre')
            .required('Obligatoire'),
        email: Yup.string()
            .email('Mail invalide')
            .required('Obligatoire'),
        password: Yup.string()
            .required('Obligatoire')
            .matches(
                /^.*(?=.{10,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Doit contenir au moins 10 caractères, un majuscule, un minuscule et un nombre"
            ),
        repeatPassword: Yup.string()
            .required('Obligatoire')
            .oneOf([Yup.ref('password')], 'Mot de passe non conforme au précédent'),
        cgu: Yup.bool()
            .oneOf([true],"Vous devez acceptez les conditions générales d'utilisation"),
        cgv: Yup.bool()
            .oneOf([true],"Vous devez acceptez les conditions générales de vente")
    });

    const loginValidationSchema = Yup.object({
        email: Yup.string()
            .email('Mail invalide')
            .required('Obligatoire'),
        password: Yup.string()
            .required('Obligatoire'),
    });

    const registerFormik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            password: '',
            repeatPassword: '',
            cgu: false,
            cgv: false
        },
        validationSchema: registerValidationSchema,
        onSubmit: async (values) => {
            const {cgu, cgv, ...userValues} = values
            await handleRegisterSubmit(userValues);
        }
    });

    const loginFormik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginValidationSchema,
        onSubmit: async (values) => {
            const loginResult = await login(dispatch, values);
            if(loginResult) {
                setLoginSuccess(true);
                const from = await state?.from || "/";
                navigate(from, { replace: true, state: {...state} });
            } else {
                setLoginFail(true);
            }
        }
    });

    const handleRegisterSubmit = async (user) => {
        try {
            await registerUser(dispatch, user);
            setIsLogin(true);
            setFetchMessage("Votre compte a été créé avec success!");
            setSeverity("success");
        }catch (error) {
            setFetchMessage(error.message);
            setSeverity("error");
        }
        setFetchStatus(true);
    }

    const handleRegisterSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchStatus(false);
    };

    const handleRegister = () => {
        setIsLogin(false);
    }

    const handleLogin = () => {
        setIsLogin(true);
    }

    const handleLoginSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setLoginSuccess(false);
    };

    const handleLoginFailClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setLoginFail(false);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowLoginPassword = () => setShowLoginPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <PageBasis>
            <GlobalContainer>
                <CustomSnackBar
                    open={fetchStatus}
                    handleSBClose={handleRegisterSBClose}
                    message={fetchMessage}
                    severity={severity}
                />
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={SBOpen} autoHideDuration={3000} onClose={handleSBClose}>
                        <Alert onClose={handleSBClose} severity="success" sx={{ width: '100%' }}>
                            {SBMessage}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={loginSuccess} autoHideDuration={3000} onClose={handleLoginSuccessClose}>
                        <Alert onClose={handleLoginSuccessClose} severity="success" sx={{ width: '100%' }}>
                            Connecté avec success !
                        </Alert>
                    </Snackbar>
                    <Snackbar open={loginFail} autoHideDuration={3000} onClose={handleLoginFailClose}>
                        <Alert onClose={handleLoginFailClose} severity="error" sx={{ width: '100%' }}>
                            Email ou mot de passe incorrect !
                        </Alert>
                    </Snackbar>
                </Stack>
                <MainContainer>
                <Top>
                    <TitleContainer onClick={handleRegister}>
                        <MenuTitle fontSize={40} color={!isLogin ? '#0652DD' : '#d7dbdd'}>Inscription</MenuTitle>
                    </TitleContainer>
                    <TitleContainer onClick={handleLogin}>
                        <MenuTitle fontSize={40} color={isLogin ? '#0652DD' : '#d7dbdd'}>Connexion</MenuTitle>
                    </TitleContainer>
                </Top>
                {
                    !isLogin &&
                    <form onSubmit={registerFormik.handleSubmit}>
                        <Body>
                            <BodyLeft>
                                <WrapperFormItem>
                                    <ProfileLabel>Prénom</ProfileLabel>
                                    <CustomTextField
                                        id="firstName"
                                        size="small"
                                        placeholder="Prénom"
                                        onChange={registerFormik.handleChange}
                                        value={registerFormik.values.firstName}
                                        error={registerFormik.touched.firstName && Boolean(registerFormik.errors.firstName)}
                                        helperText={registerFormik.touched.firstName && registerFormik.errors.firstName}
                                    />
                                </WrapperFormItem>
                                <WrapperFormItem>
                                    <ProfileLabel>Nom</ProfileLabel>
                                    <CustomTextField
                                        id="lastName"
                                        size="small"
                                        placeholder="Nom"
                                        onChange={registerFormik.handleChange}
                                        value={registerFormik.values.lastName}
                                        error={registerFormik.touched.lastName && Boolean(registerFormik.errors.lastName)}
                                        helperText={registerFormik.touched.lastName && registerFormik.errors.lastName}
                                    />
                                </WrapperFormItem>
                                <WrapperFormItem>
                                    <ProfileLabel>Téléphone</ProfileLabel>
                                    <CustomTextField
                                        id="phone"
                                        size="small"
                                        placeholder="+33 (0)0 00 00 00 00"
                                        onChange={registerFormik.handleChange}
                                        value={registerFormik.values.phone}
                                        error={registerFormik.touched.phone && Boolean(registerFormik.errors.phone)}
                                        helperText={registerFormik.touched.phone && registerFormik.errors.phone}
                                    />
                                </WrapperFormItem>
                            </BodyLeft>
                            <BodyRight>
                                <WrapperFormItem>
                                    <ProfileLabel>Adresse de messagerie</ProfileLabel>
                                    <CustomTextField
                                        id="email"
                                        size="small"
                                        placeholder="adresse de messagerie"
                                        onChange={registerFormik.handleChange}
                                        value={registerFormik.values.email}
                                        error={registerFormik.touched.email && Boolean(registerFormik.errors.email)}
                                        helperText={registerFormik.touched.email && registerFormik.errors.email}
                                    />
                                </WrapperFormItem>
                                <WrapperFormItem>
                                    <ProfileLabel>Mot de passe</ProfileLabel>
                                    <CustomTextField
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        size="small"
                                        placeholder="Mot de passe"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>

                                        }}
                                        onChange={registerFormik.handleChange}
                                        value={registerFormik.values.password}
                                        error={registerFormik.touched.password && Boolean(registerFormik.errors.password)}
                                        helperText={registerFormik.touched.password && registerFormik.errors.password}
                                    />
                                </WrapperFormItem>
                                <WrapperFormItem>
                                    <ProfileLabel>Confirmation</ProfileLabel>
                                    <CustomTextField
                                        id="repeatPassword"
                                        type={showPassword ? 'text' : 'password'}
                                        size="small"
                                        placeholder="Confirmation du mot de passe"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>

                                        }}
                                        onChange={registerFormik.handleChange}
                                        value={registerFormik.values.repeatPassword}
                                        error={registerFormik.touched.repeatPassword && Boolean(registerFormik.errors.repeatPassword)}
                                        helperText={registerFormik.touched.repeatPassword && registerFormik.errors.repeatPassword}
                                    />
                                </WrapperFormItem>
                            </BodyRight>
                        </Body>

                        <div style={{marginTop: '20px'}}>
                        <CheckBoxWrapper>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={registerFormik.values.cgu}
                                        id="cgu"
                                    />
                                }
                                id="cgu"
                                onChange={registerFormik.handleChange}
                                label={
                                    <CheckboxLabelWrapper>
                                        <StandardText>J'accepte les conditions générales d'utilisation</StandardText>
                                        <Link to={'#'} style={{textDecoration: "none"}}>
                                            <InfoContainer>
                                                <InfoIcon color="action"/>
                                            </InfoContainer>
                                        </Link>
                                    </CheckboxLabelWrapper>
                                }
                            />
                            <FormHelperText sx={{color: 'red'}}>{registerFormik.touched.cgu && registerFormik.errors.cgu}</FormHelperText>
                        </CheckBoxWrapper>
                        <CheckBoxWrapper>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={registerFormik.values.cgv}
                                        id="cgv"
                                    />
                                }
                                id="cgv"
                                onChange={registerFormik.handleChange}
                                error={registerFormik.touched.cgv && Boolean(registerFormik.errors.cgv)}
                                helperText={registerFormik.touched.cgv && registerFormik.errors.cgv}
                                label={
                                <CheckboxLabelWrapper>
                                    <StandardText>
                                        J'accepte les conditions générales de vente
                                    </StandardText>
                                    <Link to={'#'} style={{textDecoration: "none"}}>
                                        <InfoContainer>
                                            <InfoIcon color="action"/>
                                        </InfoContainer>
                                    </Link>
                                </CheckboxLabelWrapper>
                            }
                            />
                            <FormHelperText sx={{color: 'red'}}>{registerFormik.touched.cgv && registerFormik.errors.cgv}</FormHelperText>
                        </CheckBoxWrapper>
                        </div>

                        <FormButtonContainer>
                            <Button type="submit" width='20%'>S'inscrire</Button>
                        </FormButtonContainer>
                    </form>
                }
                {
                    isLogin &&
                    <form onSubmit={loginFormik.handleSubmit}>
                        <LoginBody>
                            <WrapperFormItem>
                                <ProfileLabel>Identifiant</ProfileLabel>
                                <CustomTextField
                                    id="email"
                                    size="small"
                                    placeholder="Identifiant"
                                    onChange={loginFormik.handleChange}
                                    value={loginFormik.values.email}
                                    error={loginFormik.touched.email && Boolean(loginFormik.errors.email)}
                                    helperText={loginFormik.touched.email && loginFormik.errors.email}
                                />
                            </WrapperFormItem>
                            <WrapperFormItem>
                                <ProfileLabel>Mot de passe</ProfileLabel>
                                <CustomTextField
                                    id="password"
                                    type={showLoginPassword ? 'text' : 'password'}
                                    size="small"
                                    placeholder="Mot de passe"
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowLoginPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showLoginPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                    onChange={loginFormik.handleChange}
                                    value={loginFormik.values.password}
                                    error={loginFormik.touched.password && Boolean(loginFormik.errors.password)}
                                    helperText={loginFormik.touched.password && loginFormik.errors.password}
                                />
                            </WrapperFormItem>
                            <Link to="/reset-password" style={{marginTop: 10}}>Mot de passe oublié ?</Link>
                        </LoginBody>
                        <FormButtonContainer>
                            <Button type="submit" width='20%'>Se connecter</Button>
                        </FormButtonContainer>

                    </form>
                }

            </MainContainer>
            </GlobalContainer>
        </PageBasis>
    );
}

export default Login;
