import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "../../components/styles/common-base.style";
import {MediumTitle, StandardText} from "../../components/custom-text";


export const ProfileHeader = styled(RowFlexContainer)`
  margin: 30px 0 30px 0;
  height: 40px;
  padding: 0 30px 0 30px;
  justify-content: space-between;
`

export const DetailContainer = styled(ColumnFlexContainer)`
  padding: 10px 20px 0 30px;
  width: 85%;
  align-self: flex-end;
`

export const WrapperContent = styled(ColumnFlexContainer)`
  width: 80%;
  margin: 10px 0 30px 0;
  align-self: flex-end;
`

export const WrapperItem = styled(ColumnFlexContainer)`
  margin: 30px 0 0 0;
`

export const ProfileLabel = styled(MediumTitle)`
  margin-bottom: 15px;
`

export const WrapperDeleteFile = styled(ColumnFlexContainer)`
  margin-left: 10px;
  justify-content: center;
  cursor: pointer;
`

export const DeleteFile = styled(StandardText)`
  color: red;
  text-decoration: underline;
`

export const ValidateButtonContainer = styled(ColumnFlexContainer)`
  align-items: center;
  margin-bottom: 30px;
`

export const FileNameWrapper = styled(StandardText)`
  font-style: ${props => props.italic ? 'italic' : 'normal'};
`

