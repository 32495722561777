export const COLORS = {
    "blue-l1" : "#0652DD",
    "blue-l2" : "#105CE7",
    "blue-l3" : "#1A66F1",
    "blue-l4" : "#2470FB",
    "blue-l5" : "#2E7AFF",
    "blue-l6" : "#3884FF",

    "yellow-l1" : "#F4FC00",
    "yellow-l2" : "#F2FF0A",
    "yellow-l3" : "#FCFF14",
    "yellow-l4" : "#FFFF1E",
    "yellow-l5" : "#FFFF28",
    "yellow-l6" : "#FFFF32",
}