import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "../../components/styles/common-base.style";
import {MediumTitle} from "../../components/custom-text";
import {InformationContainer} from "./SinisterDeclaration.style";
import {Select, TextField} from "@mui/material";

// Container
export const GlobalContainer = styled(ColumnFlexContainer)`
  background-color: #FBFDFF;
  padding-top: 75px;
`

// FORM
export const WrapperFormItem = styled(ColumnFlexContainer)`
  margin: 30px 0 0 0;
`

export const ProfileLabel = styled(MediumTitle)`
  margin-bottom: 15px;
`

export const UploadButton = styled.label`
  width: ${(props) => props.width};
  height: 30px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  border: solid 2px black;
  border-bottom-width: 4px;
  border-right-width: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const WrapperFileName = styled(ColumnFlexContainer)`
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`

export const WrapperUploadItem = styled(WrapperFormItem)`
  margin-left: 5%;
  margin-bottom: 50px;
`

export const SubmitButtonContainer = styled(InformationContainer)`
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10%;
`

export const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#C9CBD3',
            borderRadius: 8,
        }
    },
});

// ARROW BACKWARD IN MAIN CONTAINER HEADER
export const ArrowContainer = styled(RowFlexContainer)`
  width: 30px;
  height: 30px;
  border: solid 2px black;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border-bottom-width: 3px;
  border-right-width: 3px;
`

export const ArrowSpan = styled.span`
  font-size: 30px;
  font-weight: 900;
  color: #0652DD;
  text-shadow: 2px 2px #F4FC00;
`


export const ErrorSpan = styled.span`
  color: red;
  margin-top: 5px;
  font-size: 14px;
`

// IMAGE
export const Image = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 10px;
  object-fit: cover;
  border: solid 2px black;
  box-shadow: 5px 5px 0 0 black;
`

// Info container
export const InfoContainer = styled.span`
    margin-left: 10px;
`
