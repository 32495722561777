import styled from "styled-components";


const TextBaseStyle = styled.span`
  font-weight: ${props => props.fontWeight ? props.fontWeight : 800};
  color: ${props => props.color ? props.color : 'black'};
`

export const OutlinedText = styled.span`
  font-weight: 900;
  font-size: ${props => props.fontSize}px;
  
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #0652DD;
`

export const TextShadow = styled.span`
  font-weight: 900;
  font-size: ${props => props.fontSize}px;
  color: #0652DD;
  text-shadow: 3px 3px #F4FC00;
`

export const VeryBigTitle = styled(TextBaseStyle)`
  font-size: 30px;
`

export const BigTitle = styled(TextBaseStyle)`
  font-size: 24px;
`

export const MediumTitle = styled(TextBaseStyle)`
  font-size: 20px;
`

export const Title = styled(TextBaseStyle)`
  font-size: ${props => props.fontSize}px;
  text-decoration: none;
`

export const StandardText = styled.span`
  font-size: ${props => props.fontSize}px;
`

