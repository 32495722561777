import styled from "styled-components";


export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FormItemContainer = styled(ColumnFlexContainer)`
  width: 100%;
  margin: 0 0 25px 0;
`