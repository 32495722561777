import {publicRequest} from "./requests";
import moment from "moment/moment";
import {updateNumberOfBillsInBasket} from "../redux/login.slice";
import {checkNextGreaterThanBefore, dateToDateTime, numberOfDaysBetweenTwoDates} from "../utils/date-convert";

export const createBill = async (dispatch, {companyName, siren, amount, issuedDate, endDate}, rate, insuredId) => {
    try {
        await publicRequest.post(`bills`,
            {companyName, siren, amount: parseInt(amount), rate, issuedDate: dateToDateTime(issuedDate),
                endDate: dateToDateTime(endDate), insuredId: insuredId, isInBasket: true});
        countNumberOfBillsInBasket(dispatch, insuredId);
    }catch (e) {
        console.error("Error", e)
    }
}

export const getBillsFiltered = async (insuredId) => {
    try {
        const bills = await publicRequest.get(`/bills?filter[where][isInBasket]=false&filter[where][insuredId]=${insuredId}&filter[include][][relation]=sinister`)
        return filterBills(bills.data);
    } catch (err) {
        console.error(err);
        return {error: "Erreur lors du chargement des factures"}
    }
}

export const getBillsWithoutSinister = async (insuredId) => {
    try {
        const bills = await publicRequest.get(`/bills-not-insured?filter[where][isInBasket]=false&filter[where][insuredId]=${insuredId}&filter[include][][relation]=sinister`)
        return filterBills(bills.data);
    } catch (err) {
        console.error(err);
        return {error: "Erreur lors du chargement des factures"}
    }
}

export const getBillsInBasket = async (insuredId) => {
    try {
        const bills = await publicRequest.get(`/bills?filter[where][isInBasket]=true&filter[where][insuredId]=${insuredId}`)
        return await bills.data;
    } catch (err) {
        console.error(err)
    }
}

export const ensureBills = async (dispatch, insuredId) => {
    const bills = await publicRequest.get(`/bills?filter[where][isInBasket]=true&filter[where][insuredId]=${insuredId}`);
    bills.data.map(async (bill) => {
        bill["isInBasket"] = false;
        await publicRequest.put(`/bills/${bill.id}`, bill);
        return bill;
    })
    dispatch(updateNumberOfBillsInBasket({"numberOfBillsInBasket": 0}))
}

export const deleteBill = async (dispatch, billId, insuredId) => {
    try {
        await publicRequest.delete(`/bills/${billId}`)
        countNumberOfBillsInBasket(dispatch, insuredId);
        return {success: true, message: "La facture a été suppimé de votre panier avec succès !"};
    } catch (err) {
        console.error("Error", err);
        return {success: false, message: "Une erreur est survenue lors de la suppression de la facture"};
    }
}

export const countNumberOfBillsInBasket = async (dispatch, insuredId) => {
    try {
        const numberOfBillsInBasket = await publicRequest.get(`/bills/count?where[isInBasket]=true&where[insuredId]=${insuredId}`)
        dispatch(updateNumberOfBillsInBasket({"numberOfBillsInBasket": numberOfBillsInBasket.data.count}))
    } catch (err) {
        console.error("Error", err)
    }
}

const filterBills = (bills) => {
    const validBills = [];
    const expiredBills = [];
    bills.map((bill) => {
        if(!checkNextGreaterThanBefore(moment(), bill.endDate)) {
            expiredBills.push(bill);
        } else {
            const duration = numberOfDaysBetweenTwoDates(bill.issuedDate, bill.endDate);
            const issuedToNow = numberOfDaysBetweenTwoDates(bill.issuedDate, moment());
            bill["daysRemaining"] = numberOfDaysBetweenTwoDates(moment(), bill.endDate);
            bill["progression"] = Math.round(((issuedToNow * 100) / duration));
            validBills.push(bill);
        }
        return bill;
    });
    return {validBills, expiredBills}
}
