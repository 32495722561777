import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "../../components/styles/common-base.style";

export const EmptyBills = styled(ColumnFlexContainer)`
  justify-content: center;
  align-items: center;
`

export const BillListTitleWrapper = styled(ColumnFlexContainer)`
  align-self: start;
`

export const BillListTitle = styled.p`
  //align-self: self-start;
  margin-left: 20px;
  color: #13182B;
  font-weight: 300;
  font-size: 24px;
`

export const BillWrapper = styled.div`
  margin: 10px 0 10px 0;
  width: 75%;
`

export const BillContainer = styled(ColumnFlexContainer)`
    flex: 1;
    align-items: center;
`

export const ListBillHeader = styled(RowFlexContainer)`
  height: 40px;
  justify-content: space-between;
  margin: 30px 20px 30px 20px;
`

export const EnsureBillLayout = styled(ColumnFlexContainer)`
  height: 120px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const CentralContainer = styled(ColumnFlexContainer)`
  margin-bottom: 50px;
`
