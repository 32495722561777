import {publicRequest} from "./requests";

export const payBills = async (billsIds, insuredId) => {
  try {
    const response = await publicRequest.post(`/orders/checkout`, {insuredId, billsIds});
    return response.data;
  } catch (error) {
    console.error(error)
    throw new Error(error);
  }
}
