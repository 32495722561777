import useSnackBar from "../hooks/snack-bar.hook";
import * as Yup from "yup";
import {useFormik} from "formik";
import CustomSnackBar from "../components/custom-snack-bar";
import React from "react";
import {PageBasis} from "../components/page-basis";
import {
    ErrorSpan,
    GlobalContainer,
    SubmitButtonContainer,
    UploadButton,
    WrapperFileName
} from "./styles/common.style";
import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "../components/styles/common-base.style";
import {
    HeaderTitle,
    SinisterDeclarationHeader,
} from "./styles/SinisterDeclaration.style";
import {Button} from "../components/custom-buttons";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {ProfileLabel} from "./styles/Profile.style";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {StandardText} from "../components/custom-text";
import {uploadExcelFile} from "../services/rate.service";

const MainContainer = styled(ColumnFlexContainer)`
  width: 60%;
  align-self: center;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 2px 2px 0 black;
  background-color: white;
`

const InformationContainer = styled(ColumnFlexContainer)`
  margin: 0 0 0 50px;
`

const FileContainer = styled(ColumnFlexContainer)`
  width: 100%;
  margin-top: 20px;
`

const RATE_MATRIX = "MATRICE_DES_TAUX";
const GRADE_BY_INSURER = "GRADE_PAR_ASSUREUR";

const UploadExcelFile = () => {

    const {
        fetchStatus, setFetchStatus,
        fetchMessage, setFetchMessage,
        severity, setSeverity
    } = useSnackBar();


    const validationSchema = Yup.object({
        fileType: Yup.string()
            .required('Obligatoire'),
        file: Yup.string()
            .required('Obligatoire'),
    });

    const formik = useFormik({
        initialValues: {
            fileType: RATE_MATRIX,
            file: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await handleSubmit(values);
        }
    });

    const handleSubmit = async ({fileType, file}) => {
        const response = validateFileName(fileType, file.name);
        if (response.hasOwnProperty("error")) {
            setFetchStatus(true);
            setFetchMessage(response.error);
            setSeverity("error");
        } else {
            try {
                const serverResponse = await uploadExcelFile(fileType, file);
                setFetchStatus(true);
                setFetchMessage(serverResponse.success);
                setSeverity("success");
            } catch (error) {
                setFetchStatus(true);
                setFetchMessage(error.message);
                setSeverity("error");
            }
        }
    }

    const validateFileName = (fileType, fileName) => {
        const nameAndExtension = fileName.split(".");
        const fileNameWithoutExtension = nameAndExtension[0];
        if (nameAndExtension.length !== 2 || fileNameWithoutExtension !== fileType) {
            return {error: `Le nom du fichier est invalid, le nom attendu est '${fileType}.xlsx'`};
        }
        const fileExtension = nameAndExtension[1];
        if (fileExtension !== "xlsx") {
            return {error: `L'extension du fichier est invalid, l'extension attendue est 'xlsx'`};
        }
        return {filename: fileNameWithoutExtension+'.xlsx'};
    }

    const handleSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchStatus(false);
    };

    return (
        <PageBasis>
            <GlobalContainer>
                <CustomSnackBar
                    open={fetchStatus}
                    handleSBClose={handleSBClose}
                    message={fetchMessage}
                    severity={severity}
                />
                <MainContainer>
                    <SinisterDeclarationHeader>
                        <HeaderTitle>Charger un fichier Excel</HeaderTitle>
                    </SinisterDeclarationHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <InformationContainer>
                            <FormControl>
                                <ProfileLabel>Type de fichier</ProfileLabel>
                                <RadioGroup
                                    row
                                    id="fileType"
                                    name="fileType"
                                    defaultValue="true"
                                    onChange={formik.handleChange}
                                    value={formik.values.fileType}
                                    error={formik.touched.fileType && Boolean(formik.errors.fileType)}
                                    helperText={formik.touched.fileType && formik.errors.fileType}
                                >
                                    <FormControlLabel value={RATE_MATRIX} control={<Radio />} label="Matrice des taux" />
                                    <FormControlLabel value={GRADE_BY_INSURER} control={<Radio />} label="Grade par siren" />
                                </RadioGroup>
                            </FormControl>
                        </InformationContainer>
                        <InformationContainer>
                            <FileContainer>
                                <ProfileLabel>Fichier</ProfileLabel>
                                <RowFlexContainer>
                                    <UploadButton width="120px" >
                                        Charger
                                        <input
                                            id="file"
                                            name="file"
                                            type="file"
                                            onChange={(e) =>
                                                formik.setFieldValue('file', e.currentTarget.files[0])
                                            }
                                            style={{display: "none"}}
                                        />
                                        <FileDownloadOutlinedIcon sx={{fontSize: 25}} />
                                    </UploadButton>
                                    <WrapperFileName>
                                        <StandardText fontSize={18}>{formik.values.file.name}</StandardText>
                                    </WrapperFileName>
                                </RowFlexContainer>
                                <ErrorSpan>{formik.errors.file}</ErrorSpan>
                            </FileContainer>
                        </InformationContainer>

                        <SubmitButtonContainer>
                            <Button type="submit" width="25%">Envoyer</Button>
                        </SubmitButtonContainer>
                    </form>
                </MainContainer>
            </GlobalContainer>
        </PageBasis>
    )
}

export default UploadExcelFile;