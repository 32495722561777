import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "../../components/styles/common-base.style";
import {BigTitle, StandardText} from "../../components/custom-text";


export const SinisterDeclarationHeader = styled(RowFlexContainer)`
  margin: 30px 0 50px 0;
  height: 40px;
  padding: 0 30px 0 30px;
  align-items: center;
`

export const WrapperHeader = styled(RowFlexContainer)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const ArrowWrapper = styled(RowFlexContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeaderTitle = styled(BigTitle)`

`

export const InformationContainer = styled(ColumnFlexContainer)`
  //margin-top: 10px;
`

export const InformationTitle = styled(StandardText)`
  margin-top: 20px;
  margin-left: 5%;
`

export const TopFormContainer = styled(RowFlexContainer)`
  justify-content: space-around;
`

export const LeftForm = styled(ColumnFlexContainer)`
  width: 40%;
`

export const RightForm = styled(ColumnFlexContainer)`
  width: 40%;
`

export const CheckBoxWrapper = styled(ColumnFlexContainer)`
  padding-left: 5%;
  justify-content: center;
`

export const UploadContainer = styled(RowFlexContainer)`
`

export const BottomForm = styled(ColumnFlexContainer)`
  width: 100%;
`

