import {PageBasis} from "../components/page-basis";
import {Button} from "../components/custom-buttons";
import {FormButtonContainer, MainContainer, ResetPasswordBody, ResetPasswordTitleContainer,} from "./styles/Login.style"
import {CustomTextField, GlobalContainer, WrapperFormItem} from "./styles/common.style";
import {ProfileLabel} from "./styles/Profile.style";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useState, forwardRef} from "react";
import {Title} from "../components/custom-text";
import MuiAlert from '@mui/material/Alert';
import {useNavigate} from "react-router-dom";
import {resetPassword} from "../services/user.service";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResetPassword = () => {

    const navigate = useNavigate();

    const [resetPasswordFail, setResetPasswordFail] = useState(false);
    const [resetPwdErrorMessage, setResetPwdErrorMessage] = useState("");

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Mail invalid')
            .required('Obligatoire'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const resetPasswordResponse = await resetPassword(values.email);
            if(resetPasswordResponse.hasOwnProperty("error")) {
                setResetPasswordFail(true);
                setResetPwdErrorMessage(resetPasswordResponse.error)
            } else {
                navigate('/login', {state: {resetPasswordSuccessMsg: resetPasswordResponse.success}})
            }
        }
    });

    const handleResetPasswordSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setResetPasswordFail(false);
    };

    return (
        <PageBasis>
            <GlobalContainer>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={resetPasswordFail} autoHideDuration={5000} onClose={handleResetPasswordSBClose}>
                        <Alert onClose={handleResetPasswordSBClose} severity="error" sx={{ width: '100%' }}>
                            {resetPwdErrorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
                <MainContainer>

                <ResetPasswordTitleContainer>
                    <Title fontSize={40} >Mettre à jour votre mot de passe</Title>
                </ResetPasswordTitleContainer>
                <form onSubmit={formik.handleSubmit}>
                    <ResetPasswordBody>
                        <WrapperFormItem>
                            <ProfileLabel>Saisir le mail d'envoie du lien de reinitialisation</ProfileLabel>
                            <CustomTextField
                                id="email"
                                size="small"
                                placeholder="Identifiant"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </WrapperFormItem>
                    </ResetPasswordBody>
                    <FormButtonContainer>
                        <Button type="submit" width='20%'>Valider</Button>
                    </FormButtonContainer>
                </form>
            </MainContainer>
            </GlobalContainer>
        </PageBasis>
    );
}

export default ResetPassword;
