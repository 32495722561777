import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: "user",
    initialState: {
        currentUser: null,
        token: "",
        numberOfBillsInBasket: 0,
        isSignedIn: false,
        isFetching: false,
        error: false
    },
    reducers: {
        loginStart:(state)=>{
            state.isFetching = true;
            state.error = false;
            state.isSignedIn = false;
            state.token = "";
        },
        loginSuccess:(state, {payload})=>{
            state.isFetching = false;
            state.error = false;
            state.isSignedIn = true;
            state.token = payload.token;
            state.currentUser = {...payload.user};
            state.numberOfBillsInBasket = payload.numberOfBillsInBasket;
        },
        updateNumberOfBillsInBasket: (state, {payload})=>{
            state.numberOfBillsInBasket = payload.numberOfBillsInBasket
        },
        updateUserSuccess: (state, {payload})=>{
            state.isFetching = false;
            state.error = false;
            state.isSignedIn = true;
            state.currentUser = {...payload};
        },
        loginFail:(state)=>{
            state.isFetching = false;
            state.error = true;
            state.isSignedIn = false;
            state.token = "";
        },
        logout: (state) => {
            state.isFetching = false;
            state.error = false;
            state.isSignedIn = false;
            state.currentUser = null;
            state.token = "";
            state.numberOfBillsInBasket = 0;
        }
    }
})

export const { loginStart, loginSuccess, updateUserSuccess, updateNumberOfBillsInBasket, loginFail, logout } = loginSlice.actions;
export default loginSlice.reducer;
