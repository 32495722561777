import styled from "styled-components";
import {CardContainer} from "./containers";


const Icon = styled.div`
  //flex: 3;
  display: flex;
  justify-content: center;
`


const Text = styled.div`
  //flex: 1;
  display: flex;
  justify-content: center;
  padding: 10px;
`

const TextSpan = styled.div`
  text-align: center;
  color: ${(props) => props.textColor};
`



export const CardIconText = (props) => {

    return (
        // <div>
            <CardContainer onClick={props.onClick}  bgColor={props.bgColor} height={props.height} width={props.width} color={props.color}>
                <Icon>
                    {props.icon}
                </Icon>

                <Text>
                    <TextSpan textColor={props.textColor ? props.textColor : 'black'}>{props.text}</TextSpan>
                </Text>
            </CardContainer>
        // </div>
    )
}

CardIconText.defaultProps={
    height: 100,
    width: 170
}