import {PageBasis} from "../components/page-basis";
import {Button} from "../components/custom-buttons";
import {
    Body, BodyLeft, BodyRight, ErrorMessage, FormButtonContainer, LoginBody,
    MainContainer, MenuTitle, ResultContainer, TitleContainer, Top,
} from "./styles/Login.style"
import {CustomTextField, GlobalContainer, WrapperFormItem} from "./styles/common.style";
import {ProfileLabel} from "./styles/Profile.style";
import {useFormik} from "formik";
import * as Yup from "yup";
import {MenuItem, Select} from "@mui/material";
import {publicRequest} from "../services/requests";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {
    checkNextGreaterThanBefore,
    dateFormatForMuiDatePicker,
    dateToFrenchFormat,
    numberOfDaysBetweenTwoDates
} from "../utils/date-convert";
import {StandardText, Title} from "../components/custom-text";
import moment from "moment";
import {useSelector} from "react-redux";
import AlertDialog from "../components/dialog";
import {createBill} from "../services/bill.service";
import {checkFormDates} from "../services/ensureBill.service";
import {getRateFromServer} from "../services/rate.service";

const EvaluateBill = () => {

    const {currentUser} = useSelector(state => state.user)
    const navigate = useNavigate();

    const [insurer, setInsurer] = useState('');
    const [rate, setRate] = useState(0);

    const [siren, setSiren] = useState('');
    const [amount, setAmount] = useState('');
    const [issuedDate, setIssuedDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isFromWordPress, setIsFromWordPress] = useState(false);
    const [message, setMessage] = useState('');
    const [ensureBill, setEnsureBill] = useState(false);

    const [errorToEvaluate, setErrorToEvaluate] = useState(false);

    // DIALOG
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    useEffect(() => {
        const uri = window.location.href.split("?");
        if(uri.length > 1) {
            setIsFromWordPress(true);
            setErrorToEvaluate(false);
            const params = uri[1].split("&");
            params.map((item, index) => {
                const keyValue = item.split('=');
                if(index===0) {
                    setSiren(keyValue[1]);
                } else if(index===1) {
                    setAmount(keyValue[1]);
                } else if(index===2) {
                    setIssuedDate(dateFormatForMuiDatePicker(keyValue[1]));
                } else {
                    setEndDate(dateFormatForMuiDatePicker(keyValue[1]));
                }
            })
        }
    }, [])

    const validationSchema = Yup.object({
        siren: Yup.number()
            .typeError('Veuillez saisir un nombre')
            .required('Obligatoire'),
        amount: Yup.number()
            .typeError('Veuillez saisir un nombre')
            .required('Obligatoire'),
        issuedDate: Yup.date()
            .typeError('Veuillez saisir une date')
            .required(),
        endDate: Yup.date()
            .min(
                Yup.ref('issuedDate'),
                ({ min }) => {
                    return `La date d'échéance doit être supérieur à la date d'émission !!`
                },
            )
            .typeError('Veuillez saisir une date')
            .required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            siren: isFromWordPress ? siren : null,
            amount: isFromWordPress ? amount : null,
            issuedDate: isFromWordPress ? issuedDate : null,
            endDate: isFromWordPress ? endDate : null,
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values)
        }
    });

    const handleSubmit = async (values) => {
        values.siren = parseInt(values.siren)
        values.amount = parseInt(values.amount)

        const formDates = checkFormDates(values.issuedDate, values.endDate);
        if(!formDates.valid) {
            setMessage(formDates.errorMessage);
            return;
        }

        const result = await getRate(values);
        if(result.hasOwnProperty('error')) {
            setMessage(result.error);
            setErrorToEvaluate(true);
        } else {
            const insureAmount = (values.amount * result.rate) / 100;
            const insureAmountRounded = Math.round(insureAmount * 100) / 100;
            setDialogMessage(`Cette facture sera assurer à ${insureAmountRounded}€, 
                                    Voulez-vous assurer cette facture ?`);
            setMessage("");
            setErrorToEvaluate(false);
            setOpenDialog(true);
        }

    }

    const getRate = async ({siren, amount, issuedDate, endDate}) => {
        const duration = numberOfDaysBetweenTwoDates(issuedDate, endDate);
        const res = await getRateFromServer({siren, amount, duration})
        //setInsurer(res.data.insurer)
        //setRate(res.data.rate);
        //return res.data;
    }

    const handleValidate = () => {
        setOpenDialog(false);
        if(currentUser) {
            navigate("/ensure-bill", {state: {siren, amount, issuedDate, endDate}})
        } else {
            navigate("/login", {state: {siren, amount, issuedDate, endDate}})
        }
    }

    const handleCancel = () => {
        setOpenDialog(false);
    }

    return (
        <PageBasis>
            <GlobalContainer>
                <MainContainer>
                <Top>
                    <TitleContainer>
                        <Title fontSize={40} color='#0652DD'>Evaluez</Title>
                    </TitleContainer>
                </Top>
                    <AlertDialog
                      open={openDialog}
                      handleCancel={handleCancel}
                      handleValidate={handleValidate}
                      text={dialogMessage}
                    />
                <form onSubmit={formik.handleSubmit}>
                    <Body>
                        <BodyLeft>
                            <WrapperFormItem>
                                <ProfileLabel>Siren</ProfileLabel>
                                <CustomTextField
                                    id="siren"
                                    size="small"
                                    placeholder="Entrez votre prénom"
                                    onChange={formik.handleChange}
                                    value={formik.values.siren}
                                    error={formik.touched.siren && Boolean(formik.errors.siren)}
                                    helperText={formik.touched.siren && formik.errors.siren}
                                />
                            </WrapperFormItem>
                            <WrapperFormItem>
                                <ProfileLabel>Prix</ProfileLabel>
                                <CustomTextField
                                    id="amount"
                                    size="small"
                                    placeholder="Entrez votre nom"
                                    onChange={formik.handleChange}
                                    value={formik.values.amount}
                                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                                    helperText={formik.touched.amount && formik.errors.amount}
                                />
                            </WrapperFormItem>
                        </BodyLeft>
                        <BodyRight>
                            <WrapperFormItem>
                                <ProfileLabel>Date d'émission</ProfileLabel>
                                <DesktopDatePicker
                                    inputFormat="DD/MM/yyyy"
                                    value={formik.values.issuedDate}
                                    onChange={(value) => formik.setFieldValue("issuedDate", value)}
                                    renderInput={(params) =>
                                        <CustomTextField
                                            id="issuedDate"
                                            size="small"
                                            {...params}
                                            error={formik.touched.issuedDate && Boolean(formik.errors.issuedDate)}
                                            helperText={formik.touched.issuedDate && formik.errors.issuedDate}
                                        />}
                                />
                            </WrapperFormItem>
                            <WrapperFormItem>
                                <ProfileLabel>Date d'échéance</ProfileLabel>
                                <DesktopDatePicker
                                    id="endDate"
                                    inputFormat="DD/MM/YYYY"
                                    value={formik.values.endDate}
                                    onChange={(value) => formik.setFieldValue("endDate", value)}
                                    renderInput={(params) =>
                                        <CustomTextField
                                            id="endDate"
                                            size="small"
                                            {...params}
                                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                            helperText={formik.touched.endDate && formik.errors.endDate}
                                        />}
                                />
                            </WrapperFormItem>
                        </BodyRight>
                    </Body>
                    <ResultContainer>
                        <ErrorMessage fontSize={20} color="red" >{message}</ErrorMessage>
                    </ResultContainer>
                    <FormButtonContainer>
                        <Button type="submit" width='20%'>Evaluer la facture</Button>
                    </FormButtonContainer>
                </form>
            </MainContainer>
            </GlobalContainer>
        </PageBasis>
    );
}

export default EvaluateBill;
