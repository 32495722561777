import styled from "styled-components";
import {MediumTitle, Title} from "./custom-text";
import {ButtonWithIcon, IconButton} from "./custom-buttons";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../redux/login.slice";
import {ColumnFlexContainer} from "./styles/common-base.style";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {Badge} from "@mui/material";
import React from "react";
import {sinistersReset} from "../redux/sinister.slice";
import {billsReset} from "../redux/bills.slice";

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 80px;
`

const Left = styled.div`
  flex: 3;
  display: flex;
  height: 100%;
  padding-left: 50px;
`

const Center = styled.div`
  flex: 4;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Right = styled.div`
  flex: 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
`

const ShoppingCartWrapper = styled(ColumnFlexContainer)`
  margin-right: 20px;
`

export const Header = () => {

    const isSignedIn = useSelector(state=>state.user.isSignedIn);
    const numberOfBillsInBasket = useSelector(state=>state.user.numberOfBillsInBasket);
    const {currentUser} = useSelector(state => state.user);
    const dispatch = useDispatch();

    const logOut = async () => {
        dispatch(logout());
        dispatch(sinistersReset());
        dispatch(billsReset());
    }

    return (
        <Container>
            <Left>
                <Link to={'/'} style={{marginRight: "20px"}}>
                  <img alt="logo" src={require("../assets/logo.PNG")} style={{width: "60px", height: "60px"}} />
                </Link>
                <Title color="#0652DD" fontSize={50}>Leezay</Title>
            </Left>
            <Center>
                <Link to={'/'} style={{textDecoration: "none"}}>
                    <MediumTitle>Home</MediumTitle>
                </Link>
                <MediumTitle>A propos</MediumTitle>
                <MediumTitle>Aide</MediumTitle>
                <Link to={'/contact'} style={{textDecoration: "none"}}>
                    <MediumTitle>Contact</MediumTitle>
                </Link>
                {currentUser?.isAdmin &&
                    <Link to={'/upload-excel-file'} style={{textDecoration: "none"}}>
                        <MediumTitle>Upload</MediumTitle>
                    </Link>
                }

            </Center>
            <Right>
                {
                    isSignedIn &&
                    <>
                        <Link to="/basket" style={{color: "black"}}>
                            <ShoppingCartWrapper>
                                <Badge badgeContent={numberOfBillsInBasket} color="primary">
                                    <ShoppingCartOutlinedIcon sx={{fontSize: 40}} />
                                </Badge>
                            </ShoppingCartWrapper>
                        </Link>
                        <Link to={'/login'} style={{textDecoration: "none"}}>
                            <IconButton
                                width='180px'
                                onClick={logOut}
                            >
                                Se déconnecter
                                <LogoutIcon sx={{fontSize: 25}}/>
                            </IconButton>
                        </Link>
                    </>

                }
                {
                    !isSignedIn &&
                    <Link to={'/login'} style={{textDecoration: "none"}}>
                        <ButtonWithIcon
                            width='180px'
                            icon={<PersonOutlineOutlinedIcon sx={{fontSize: 25}}/>}>
                            Se connecter
                        </ButtonWithIcon>
                    </Link>
                }

            </Right>

        </Container>
    )
}
