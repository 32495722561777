import {publicRequest} from "./requests";

export const getRateFromServer = async ({siren, amount, duration}) => {
    try {
        const gradeByInsurer = await publicRequest.post("insurer-grade-by-siren",
            { "siren": siren.toString() });

        if(gradeByInsurer && gradeByInsurer.data) {
            console.log(gradeByInsurer.data)
            const rateFromServer = await publicRequest.post("rate", {
                "gradeByInsurer": gradeByInsurer.data,
                "amount": parseInt(amount),
                "duration": duration,
                "localization": "France"
            });
            console.log(rateFromServer.data)
            return await rateFromServer.data;
        }
    }catch (error) {
        console.error("Error", error);
        throw new Error(error.response.data.error.message);
    }
}

export const uploadExcelFile = async (fileType, file) => {
    try {
        const bodyWithFile= new FormData();
        bodyWithFile.append('files', file);
        bodyWithFile.append('fileType', fileType);
        const response = await publicRequest.post("/rate/upload-excel-file", bodyWithFile);
        return response.data;
    }catch (error) {
        console.error("Error", error);
        throw new Error(error.response.data.error.message);
    }
}
