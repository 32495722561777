import {PageBasis} from "../components/page-basis";
import {BigTitle} from "../components/custom-text";
import {
    ContactContainer,
    MotionContainer,
    QuestionsContainer,
    RepeatedText,
    WrapperQuestions,
    WrapperTitle,
    WrapperTheme,
    ThemeContainer,
    ThemeButton,
    ButtonTheme,
    TextScroll,
    WrapperContactText,
    TextCustom,
    BaseContainer,
    PageTitle, DescriptionText
} from "./styles/Contact-FAQ.style";
import {AccordionLeezay} from "../components/accordion";
import {RegularButton} from "../components/custom-buttons";
import {useState} from "react";
import {Link} from "react-router-dom";
import {publicRequest} from "../services/requests";
import {GlobalContainer} from "./styles/common.style";

const FAQ = () => {

    const [similarTheme, setSimilarTheme] = useState(false);
    const arrayOfTextScroll = new Array(20).fill(0);
    const [faqs, setFaqs] = useState([]);
    const [themes, setThemes] = useState([]);
    const [similarQuestions, setSimilarQuestions] = useState([]);

    const handleSimilarTheme = async (themeId) => {
        setSimilarTheme(true);
        const res = await publicRequest.get(`themes/${themeId}/faqs`)
        setSimilarQuestions(res.data)
    }

    useState( () => {
        const getFaq = async () => {
            try {
                const resFaqs = await publicRequest.get(`faqs`)
                const resThemes = await publicRequest.get(`themes`)
                setFaqs(resFaqs.data)
                setThemes(resThemes.data)
            } catch (err) {
                console.log(err);
            }
        }
        getFaq();
    }, []);

    return (
        <PageBasis>
            <GlobalContainer>
                <BaseContainer>
                    <WrapperTitle>
                        <PageTitle>La foire aux questions</PageTitle>
                        <DescriptionText>Voici l'endroit parfait si vous cherchez des réponses à vos questions!</DescriptionText>
                    </WrapperTitle>
                    <WrapperQuestions>
                        <BigTitle>Des questions que tout le monde se posse</BigTitle>
                        <QuestionsContainer>
                            {
                                faqs.map((item, index)=>{
                                    if(index<3) {
                                        return (
                                            <AccordionLeezay
                                                key={item.id}
                                                summary={item.title}
                                                details={item.description} />
                                        )
                                    }
                                    return null;
                                })
                            }
                        </QuestionsContainer>
                    </WrapperQuestions>
                    <WrapperTheme>
                        <BigTitle>D'autres sujets similaires</BigTitle>
                        <ThemeContainer>
                            {
                                themes?.map((theme) =>
                                    <ThemeButton key={theme.id}>
                                        <ButtonTheme onClick={handleSimilarTheme.bind(null, theme.id)}>{theme.name}</ButtonTheme>
                                    </ThemeButton>
                                )
                            }
                        </ThemeContainer>
                    </WrapperTheme>
                </BaseContainer>

                {!similarTheme &&
                    <>
                    <RepeatedText>
                        <MotionContainer>
                            {
                                arrayOfTextScroll.map(()=> {
                                    return <TextScroll>&#x1F310;  &nbsp;&nbsp;Une question ?</TextScroll>
                                })
                            }
                        </MotionContainer>
                    </RepeatedText>

                    <ContactContainer>
                        <WrapperContactText>
                            <BigTitle>Vous ne trouvez pas ?</BigTitle>
                            <TextCustom>Posez nous directement la question si vous ne trouvez pas la réponse
                                ici</TextCustom>
                        </WrapperContactText>
                        <Link to={'/contact'}>
                            <RegularButton width={'200px'}>Nous contacter</RegularButton>
                        </Link>
                    </ContactContainer>

                    <RepeatedText>
                        <MotionContainer>
                            {
                                arrayOfTextScroll.map(()=> {
                                    return <TextScroll>&#x1F310;  &nbsp;&nbsp;Une question ?</TextScroll>
                                })
                            }
                        </MotionContainer>
                    </RepeatedText>
                </>}

                {similarTheme &&
                    <BaseContainer>
                        <WrapperQuestions>
                            <BigTitle>Questions associées</BigTitle>
                            <QuestionsContainer>
                                {
                                    similarQuestions.map((item, index)=>{
                                        if(index<3) {
                                            return (
                                                <AccordionLeezay
                                                    key={item.id}
                                                    summary={item.title}
                                                    details={item.description} />
                                            )
                                        }
                                        return null;
                                    })
                                }
                            </QuestionsContainer>
                        </WrapperQuestions>
                    </BaseContainer>
                }
            </GlobalContainer>

        </PageBasis>
    )
}

export default FAQ;
