import {Box, Grid} from "@mui/material";
import styled from "styled-components";
import {MediumTitle, StandardText} from "./custom-text";
import {StyledDialog} from "./custom-backdrop";
import Button from "@mui/material/Button";

const CustomText = styled(StandardText)`
  text-align: center;
`;

const EnsureBillDialog = (props) => {
  
  const renderInfoLine = (title, value) => {
    return (
      <Grid container>
        <MediumTitle>{title} : &nbsp;&nbsp;&nbsp;</MediumTitle>
        <StandardText fontSize={20}>{value}</StandardText>
      </Grid>
    )
  }

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleValidateAndPay = () => {
    props.handleValidate();
  };

  const handleAddNewBill = () => {
    props.handleAddNewBill();
  };

  return (
    <StyledDialog
      onClose={handleCancel}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      fullWidth={true}
    >
      <Box sx={{ display: 'flex', flexDirection: "column", margin: '50px 30px 10px 30px' }}>
        {renderInfoLine("Raison sociale", props.companyName)}
        {renderInfoLine("Numéro Siren", props.siren)}
        {renderInfoLine("Montant de la facture", new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(props.amount))}
        {renderInfoLine("Durée du crédit", props.duration + " jours")}
        
        <Box mt={4} width="100%" display="flex" flexDirection="column">
          <CustomText fontSize={20}>
            Cette facture peut-être assurée
          </CustomText>
          <br />
          <CustomText fontSize={20}>
            pour un montant maximum de {props.ensureAmount}€ HT
          </CustomText>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px' }}>
        <Box sx={{ display: 'flex', marginBottom: '10px' }}>
          <Button onClick={handleValidateAndPay} variant="contained">
            <StandardText fontSize={14}>Ajouter au panier et payer</StandardText>
          </Button>
        </Box>
        <Box sx={{ display: 'flex', marginBottom: '10px' }}>
          <Button onClick={handleAddNewBill} variant="contained">
            <StandardText fontSize={14}>Ajouter une autre facture</StandardText>
          </Button>
        </Box>
        <Box sx={{ display: 'flex', marginBottom: '10px' }}>
          <Button onClick={handleCancel} variant="outlined">
            <StandardText fontSize={14}>Abandonner</StandardText>
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}

export default EnsureBillDialog;
