import React, {useEffect, useState} from "react";
import {BigTitle, StandardText} from "../components/custom-text";
import {PageBasis} from "../components/page-basis";
import MainContainerWithMenu from "../components/main-container-with-menu";
import {useDispatch, useSelector} from "react-redux";
import {billPage} from "../redux/connectedMenu.slice";
import {CustomTextField, GlobalContainer} from "./styles/common.style";
import SearchIcon from '@mui/icons-material/Search';
import {Button} from "../components/custom-buttons";
import {Bill} from "../components/Bill";
import {
    BillContainer,
    BillListTitle,
    BillListTitleWrapper,
    BillWrapper,
    CentralContainer,
    EmptyBills,
    EnsureBillLayout,
    ListBillHeader
} from "./styles/ListBill.styled";
import {useNavigate} from "react-router-dom";
import AlertDialog from "../components/dialog";
import {countNumberOfBillsInBasket, getBillsFiltered} from "../services/bill.service";
import CustomSnackBar from "../components/custom-snack-bar";
import useSnackBar from "../hooks/snack-bar.hook";

const ListBill = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {currentUser} = useSelector(state => state.user);
    const [openDialog, setOpenDialog] = useState(false);

    const {
        fetchStatus, setFetchStatus,
        fetchMessage, setFetchMessage,
        severity, setSeverity
    } = useSnackBar();

    const [validBills, setValidBills] = useState([]);
    const [expiredBills, setExpiredBills] = useState([]);

    useEffect(() => {
        dispatch(billPage());
        countNumberOfBillsInBasket(dispatch, currentUser?.insured?.id);
        async function fetchData() {
            const result = await getBillsFiltered(currentUser?.insured?.id);
            if (result.hasOwnProperty("error")) {
                setFetchStatus(true);
                setFetchMessage(result.error);
                setSeverity("error");
            } else {
                setValidBills(result.validBills);
                setExpiredBills(result.expiredBills);
            }
        }
        fetchData();
    }, []);

    const handleClick = () => {
        if(!currentUser.insured || !currentUser.insured.isProfileCompleted) {
            setOpenDialog(true);
        } else {
            navigate("/ensure-bill")
        }
    }

    const handleValidate = () => {
        setOpenDialog(false);
        navigate("/profile", { state: {editProfile: true} })
    }

    const handleCancel = () => {
        setOpenDialog(false);
    }

    const handleSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFetchStatus(false);
    };

    return (
        <PageBasis>
            <GlobalContainer>
                <CustomSnackBar
                    open={fetchStatus}
                    handleSBClose={handleSBClose}
                    message={fetchMessage}
                    severity={severity}
                    />
                <MainContainerWithMenu>
                    <ListBillHeader>
                        <BigTitle>Mes factures</BigTitle>
                        <CustomTextField
                            id="search"
                            size="small"
                            placeholder="Recherchez"
                            style={{width: "60%"}}
                            InputProps={{
                                endAdornment: <SearchIcon/>
                            }}
                        />
                    </ListBillHeader>
                    <EnsureBillLayout>
                        <BigTitle></BigTitle>
                        <Button width='230px' onClick={handleClick}>
                            Assurer une nouvelle facture
                        </Button>
                        <AlertDialog
                            open={openDialog}
                            handleCancel={handleCancel}
                            handleValidate={handleValidate}
                            text={<StandardText>Afin d'assurer votre première facture, veuillez compléter vore profil</StandardText>}
                        />
                    </EnsureBillLayout>
                    {
                        ((validBills.length === 0) && (expiredBills.length === 0)) &&
                        <EmptyBills>
                            <BillListTitle>Vous n'avez pas encore de factures assurées</BillListTitle>
                        </EmptyBills>
                    }
                    <CentralContainer>
                        <BillContainer>
                            {
                                (validBills.length !== 0) &&
                                <>
                                <BillListTitleWrapper>
                                    <BillListTitle>Facture arrivant à échéance</BillListTitle>
                                </BillListTitleWrapper>
                                    {
                                        validBills.map((bill) =>
                                            <BillWrapper key={bill.id}>
                                                <Bill key={bill.id} bill={bill}/>
                                            </BillWrapper>
                                        )
                                    }
                                </>
                            }
                        </BillContainer>
                        <BillContainer>
                            {
                                (expiredBills.length !== 0) &&
                                <>
                                <BillListTitleWrapper>
                                    <BillListTitle>Échéance depassée</BillListTitle>
                                </BillListTitleWrapper>
                                    {
                                        expiredBills.map((bill) =>
                                            <BillWrapper key={bill.id}>
                                                <Bill key={bill.id} bill={bill}/>
                                            </BillWrapper>
                                        )
                                    }
                                </>
                            }
                        </BillContainer>
                    </CentralContainer>
                </MainContainerWithMenu>
            </GlobalContainer>
        </PageBasis>
    )
}

export default ListBill;
