import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "./common-base.style";

export const Layout1= styled(RowFlexContainer)`
  flex: 1;
  justify-content: space-between;
  width: 75%;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid black;
  box-shadow: 1px 0 0 0 #000000;
  margin: ${props=>props.margin}
`

export const FirstPart= styled(ColumnFlexContainer)`
  margin-right: 50px;
  justify-content: space-between;
  //border: 1px solid blueviolet;
`

export const SecondPart= styled(ColumnFlexContainer)`
  align-items: end;
  //border: 1px solid red;
`

export const InformationContainer= styled(RowFlexContainer)`
  //border: 1px solid red;
  justify-content: space-between;
  margin-top: 10px;
`

export const Rate= styled.span`
  font-size: 20px;
  color: #0652DD;
  font-weight: 600;
  //border: 1px solid blueviolet;
`