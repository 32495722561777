import {PageBasis} from "../components/page-basis";
import {Button} from "../components/custom-buttons";
import {FormButtonContainer, MainContainer, ResetPasswordBody, ResetPasswordTitleContainer,} from "./styles/Login.style"
import {CustomTextField, GlobalContainer, WrapperFormItem} from "./styles/common.style";
import {ProfileLabel} from "./styles/Profile.style";
import {useFormik} from "formik";
import * as Yup from "yup";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Title} from "../components/custom-text";
import MuiAlert from '@mui/material/Alert';
import {useNavigate} from "react-router-dom";
import {updatePassword} from "../services/user.service";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UpdatePassword = () => {

    const navigate = useNavigate();

    const [updatePwdFail, setUpdatePwdFail] = useState(false);
    const [updatePwdFailErrorMsg, setUpdatePwdFailErrorMsg] = useState("");

    const handleUpdatePwdSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdatePwdFail(false);
    };

    const validationSchema = Yup.object({
        password: Yup.string()
          .required('Obligatoire')
          .matches(
            /^.*(?=.{10,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Doit contenir au moins 10 caractères, un majuscule et un nombre"
          ),
        repeatPassword: Yup.string()
          .required('Obligatoire')
          .oneOf([Yup.ref('password'), null], 'Mot de passe non conforme au précédent'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            repeatPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const uriParams = window.location.href.split("?")[1];
            const resetKey = uriParams.split("=")[1];
            const updatePwdResponse = await updatePassword({resetKey, password: values.password, confirmPassword: values.repeatPassword})
            if(updatePwdResponse.hasOwnProperty("error")) {
                setUpdatePwdFail(true);
                setUpdatePwdFailErrorMsg(updatePwdResponse.error);
            } else {
                navigate('/login', {state: {updatePwdSuccessMsg: updatePwdResponse.success}});
            }
        }
    });

    return (
        <PageBasis>
            <GlobalContainer>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={updatePwdFail} autoHideDuration={3000} onClose={handleUpdatePwdSBClose}>
                        <Alert onClose={handleUpdatePwdSBClose} severity="error" sx={{ width: '100%' }}>
                            {updatePwdFailErrorMsg}
                        </Alert>
                    </Snackbar>
                </Stack>
                <MainContainer>

                <ResetPasswordTitleContainer>
                    <Title fontSize={40} >Saisir votre nouveau mot de passe</Title>
                </ResetPasswordTitleContainer>
                <form onSubmit={formik.handleSubmit}>
                    <ResetPasswordBody>
                        <WrapperFormItem>
                            <ProfileLabel>Nouveau mot de passe</ProfileLabel>
                            <CustomTextField
                              id="password"
                              type="password"
                              size="small"
                              placeholder="Nouveau mot de passe"
                              onChange={formik.handleChange}
                              value={formik.values.password}
                              error={formik.touched.password && Boolean(formik.errors.password)}
                              helperText={formik.touched.password && formik.errors.password}
                            />
                        </WrapperFormItem>
                        <WrapperFormItem>
                            <ProfileLabel>Confirmation</ProfileLabel>
                            <CustomTextField
                              id="repeatPassword"
                              type="password"
                              size="small"
                              placeholder="Confirmation du nouveau mot de passe"
                              onChange={formik.handleChange}
                              value={formik.values.repeatPassword}
                            />
                        </WrapperFormItem>
                    </ResetPasswordBody>
                    <FormButtonContainer>
                        <Button type="submit" width='20%'>Valider</Button>
                    </FormButtonContainer>
                </form>
            </MainContainer>
            </GlobalContainer>
        </PageBasis>
    );
}

export default UpdatePassword;
