import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "../../components/styles/common-base.style";

export const BasketMainContainer = styled(ColumnFlexContainer)`
  align-items: center;
  margin-bottom: 20px;
`

export const ContinueContainer = styled(RowFlexContainer)`
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
`

export const TitleBasket = styled.span`
  align-self: self-start;
  margin-left: 20px;
  color: #13182B;
  font-weight: 250;
  font-size: 24px;
`

export const TotalBillContainer = styled(RowFlexContainer)`
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const TotalBillAmount = styled.span`
  font-size: 24px;
`

export const TotalBillText = styled(TitleBasket)`
 
`











