import {checkNextGreaterThanBefore} from "../utils/date-convert";
import moment from "moment";


export const checkFormDates = (issuedDate, endDate) => {
  if(!checkNextGreaterThanBefore(moment(), endDate)) {
    return {valid: false, errorMessage: "Cette facture n'est pas assurable car elle a déjà expiré"};
  } else if(!checkNextGreaterThanBefore(issuedDate, endDate)) {
    return {valid: false, errorMessage: "Cette facture n'est pas assurable car elle expire le jour même de l'émission"};
  }
  return {valid: true};
}
