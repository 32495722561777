import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import {forwardRef} from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackBar = ({open, handleSBClose, message, severity}) => {

  const handleCustomSBClose = () => {
    handleSBClose();
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleCustomSBClose}>
        <Alert onClose={handleCustomSBClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  )
}

export default CustomSnackBar;
