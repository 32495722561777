import { createSlice } from "@reduxjs/toolkit";

const registerSlice = createSlice({
    name: "register",
    initialState: {
        isFetching: false,
        success: false,
        error: false
    },
    reducers: {
        registerStart: (state) => {
            state.isFetching = true;
            state.error = false;
            state.success = false;
        },
        registerSuccess: (state,action) => {
            state.isFetching = false;
            state.success = true;
            state.error = false;
        },
        registerFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.success = false;
        },
        registerReinitialize: (state) => {
            state.isFetching = false;
            state.error = false;
            state.success = false;
        },
    }
})

export const { registerStart, registerSuccess, registerFailure, registerReinitialize } = registerSlice.actions;
export default registerSlice.reducer;
