import {PageBasis} from "../components/page-basis";
import MainContainerWithMenu from "../components/main-container-with-menu";
import {ArrowContainer, ArrowSpan, GlobalContainer} from "./styles/common.style";
import {HeaderTitle, SinisterDeclarationHeader} from "./styles/SinisterDeclaration.style";
import {
    BasketMainContainer,
    ContinueContainer,
    TitleBasket,
    TotalBillAmount,
    TotalBillContainer,
    TotalBillText
} from "./styles/Basket.style";
import React, {forwardRef, useEffect, useState} from "react";
import {BillBasket} from "../components/billBasket";
import {Button, RegularButton} from "../components/custom-buttons";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {deleteBill, getBillsInBasket} from "../services/bill.service";
import {numberOfDaysBetweenTwoDates} from "../utils/date-convert";
import {BillListTitle, EmptyBills} from "./styles/ListBill.styled";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import {payBills} from "../services/basket.service";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Basket = () => {

    const dispatch = useDispatch();
    const {currentUser} = useSelector(state => state.user);
    const numberOfBillsInBasket = useSelector(state=>state.user.numberOfBillsInBasket);
    const [billsInBasket, setBillsInBasket] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const [removeFromBasketSBOpen, setRemoveFromBasketSBOpen] = useState(false);
    const [removeFromBasketStatus, setRemoveFromBasketStatus] = useState("");
    const [removeFromBasketMessage, setRemoveFromBasketMessage] = useState("");

    useEffect(() => {
        async function fetchBillsInBasket() {
            const billsInBasket = await getBillsInBasket(currentUser?.insured?.id);
            if(billsInBasket) {
                setBillsInBasket(billsInBasket);
                setTotalAmount(calculateTotalAmount(billsInBasket));
            }
        }
        fetchBillsInBasket();

    }, []);

    const removeBillFromBasket = async (billId) => {

        if (currentUser && currentUser.insured) {
            const result = await deleteBill(dispatch, billId, currentUser.insured.id);
            if (result.success) {
                const filteredBills = billsInBasket.filter((bill) => bill.id !== billId)
                setBillsInBasket(filteredBills);
                setTotalAmount(calculateTotalAmount(filteredBills));
                setRemoveFromBasketStatus("success");
            } else {
                setRemoveFromBasketStatus("error");
            }
            setRemoveFromBasketMessage(result.message);
            setRemoveFromBasketSBOpen(true);
        }
    }

    const calculateTotalAmount = (billsInBasket) => {
        return billsInBasket.reduce(function (accumulateur, valeurCourante) {
            return accumulateur + ((valeurCourante.amount * valeurCourante.rate)/100);
        }, 0);
    }

    const handleRemoveFromBasketSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setRemoveFromBasketSBOpen(false);
    };
    
    const checkout = async () => {
      try {
          console.log("Checkout")
          const billsIds = billsInBasket.map((bill) => bill.id);
          const response = await payBills(billsIds, currentUser.insured.id);
          if (response.error) {
              console.log(response.error)
          } else if (response.url) {
              window.location.href = response.url;
          }
      } catch (error) {
          console.log(error)
      }
    }
    
    return (
        <PageBasis>
            <GlobalContainer>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={removeFromBasketSBOpen} autoHideDuration={5000} onClose={handleRemoveFromBasketSBClose}>
                        <Alert onClose={handleRemoveFromBasketSBClose} severity={removeFromBasketStatus} sx={{ width: '100%' }}>
                            {removeFromBasketMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
                <MainContainerWithMenu>

                    <SinisterDeclarationHeader>
                        {/*<ArrowContainer>*/}
                        {/*    <ArrowSpan>&#8592;</ArrowSpan>*/}
                        {/*</ArrowContainer>*/}
                        <HeaderTitle>Panier</HeaderTitle>
                    </SinisterDeclarationHeader>
                    {
                        billsInBasket.length === 0 &&
                        <EmptyBills>
                            <BillListTitle>Vous n'avez pas encore de facture dans votre panier</BillListTitle>
                        </EmptyBills>
                    }
                    {
                        billsInBasket.length !== 0 &&
                        <>
                            <TitleBasket>Vous avez {numberOfBillsInBasket} factures dans votre panier</TitleBasket>
                            <BasketMainContainer>
                                {billsInBasket.map(bill =>
                                    <BillBasket
                                        siren={bill.companyName}
                                        rate={bill.rate}
                                        amount={bill.amount}
                                        nbDay={numberOfDaysBetweenTwoDates(bill.issuedDate, bill.endDate)}
                                        billId={bill.id}
                                        onRemoveBill={removeBillFromBasket}
                                        key={bill.id}
                                    />
                                )}
                            </BasketMainContainer>

                            <TitleBasket>Récapitulatif</TitleBasket>
                            <TotalBillContainer>
                                <TotalBillText>Montant total à payer</TotalBillText>
                                <TotalBillAmount>{totalAmount} €</TotalBillAmount>
                            </TotalBillContainer>

                            <ContinueContainer>
                                {/*<Link to="/checkout">*/}
                                    <Button width='200px' onClick={checkout}>
                                        Continuer le paiement
                                    </Button>
                                {/*</Link>*/}
                            </ContinueContainer>
                        </>
                    }
                </MainContainerWithMenu>
            </GlobalContainer>
        </PageBasis>

    )
}

export default Basket;
