import styled from "styled-components"
import {COLORS} from "../../utils/colors";
import {RowFlexContainer} from "./common-base.style";


export const Layout1= styled(RowFlexContainer)`
  flex: 1;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid black;
  box-shadow: 1px 0 0 0 #000000;
  background: ${props => props.hasSinister ? '#fd7979' : '#ffffff'};
`

export const Title= styled.p`
  font-weight: bold;
`



export const FirstPart= styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 50px;
  justify-content: space-between;
  //border: 1px solid blueviolet;
`

export const SecondPart= styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const Price= styled.span`
  color: ${COLORS["blue-l1"]};
  font-weight: bold;
  margin-bottom: 5px;
`
