import {PageBasis} from "../components/page-basis";
import MainContainerWithMenu from "../components/main-container-with-menu";
import {
    HeaderTitle,
    InformationContainer, InformationTitle, LeftForm, RightForm,
    SinisterDeclarationHeader,
    TopFormContainer, WrapperHeader
} from "./styles/SinisterDeclaration.style";
import {
    WrapperFormItem, SubmitButtonContainer, CustomTextField, GlobalContainer
} from "./styles/common.style";
import {InputAdornment} from "@mui/material";
import {Button, IconButton} from "../components/custom-buttons";
import {ProfileLabel} from "./styles/Profile.style";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React, {useState} from "react";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import * as Yup from "yup";
import {useFormik} from "formik";
import {createBill} from "../services/bill.service";
import {useDispatch, useSelector} from "react-redux";
import {getRateFromServer} from "../services/rate.service";
import {Link, useNavigate} from "react-router-dom";
import {checkFormDates} from "../services/ensureBill.service";
import CustomBackdrop from "../components/custom-backdrop";
import EnsureBillDialog from "../components/ensure-bill-dialog";
import {numberOfDaysBetweenTwoDates} from "../utils/date-convert";

const WAINTING_MESSAGE = "Nous recherchons pour vous les meilleures conditions d'assurance-crédit";
const EnsureBill = () => {

    // DISPATCH
    const dispatch = useDispatch();

    // SELECTOR
    const {currentUser} = useSelector(state => state.user)

    // NAVIGATE
    const navigate = useNavigate();

    // FORM
    const [formValues, setFormValues] = useState({})
    const [resetForm, setResetForm] = useState(()=>{})
    const [rate, setRate] = useState(0)

    // DIALOG
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [canBeInsured, setCanBeInsured] = useState(true);
    const [billDuration, setBillDuration] = useState(0);

    // BACKDROP
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [ensureAmount, setEnsureAmount] = useState(0);

    // INVALIDATE ERROR
    const [invalidDateError, setInvalidDateError] = useState("");
    const [openInvalidDate, setOpenInvalidDate] = useState(false);

    const validationSchema = Yup.object({
        companyName: Yup.string()
            .required('Obligatoire'),
        siren: Yup.number()
          .typeError('Veuillez saisir un nombre')
          .required('Obligatoire')
          .test({
            name: 'len',
            test: (value)=>value?.toString().length === 9,
            message: 'Attention, le numéro SIREN est erroné'
          }),
        amount: Yup.number()
            .typeError('Veuillez saisir un nombre')
            .required('Obligatoire')
            .min(1, "Nous n'assurons pas les factures de moins de 1€")
            .max(50000, "Nous n'assurons pas les factures de plus de 50000€"),
        issuedDate: Yup.date()
            .typeError('Veuillez saisir une date')
            .required(),
        endDate: Yup.date()
            .min(
                Yup.ref('issuedDate'),
                ({ min }) => `La date d'échéance doit être spérieur à la date d'émission !!`,
            )
            .typeError('Veuillez saisir une date')
            .required(),
    });



    const formik = useFormik({
        initialValues: {
            companyName: '',
            siren: '',
            amount: '',
            issuedDate: null,
            endDate: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleFormSubmit(values, resetForm)
        }
    });

    const handleValidate = async () => {
        if(canBeInsured) {
            await createBill(dispatch, formValues, rate, currentUser.insured.id)
            resetForm();
            navigate("/basket");
        }
        setOpenDialog(false);
    }

    const handleCancel = () => {
        setOpenDialog(false);
    }

    const handleAddNewBill = async () => {
        if(canBeInsured) {
            await createBill(dispatch, formValues, rate, currentUser.insured.id)
            formik.resetForm();
        }
        setOpenDialog(false);
    }

    const handleFormSubmit = async (values, resetForm) => {
        const formDates = checkFormDates(values.issuedDate, values.endDate);
        if(!formDates.valid) {
            setDialogMessage(formDates.errorMessage);
            setCanBeInsured(false);
            setOpenInvalidDate(true);
            setInvalidDateError(formDates.errorMessage);
        } else {
            setOpenBackdrop(true);
            const {siren, amount, issuedDate, endDate} = values;
            const duration = numberOfDaysBetweenTwoDates(issuedDate, endDate);
            try {
                const result = await getRateFromServer(
                    {siren, amount, duration});
                setBillDuration(duration);
                const insureAmount = (parseInt(values.amount) * result.rate) / 100;
                const insureAmountRounded = Math.round(insureAmount * 100) / 100;
                setEnsureAmount(insureAmountRounded);
                setDialogMessage(`Cette facture de ${values.amount}€ peut-être assurée pour un montant maximum de ${insureAmountRounded}€ HT`)
                setCanBeInsured(true)
                setRate(result.rate);
                handleBackdropClose();
                setOpenDialog(true);
                setFormValues(values);
                setResetForm(() => resetForm);
            } catch (error) {
                setInvalidDateError(error.message)
                handleBackdropClose();
                setOpenInvalidDate(true);
            }
        }
    }

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
    }

    const handleInvalidDateDialogClose = () => {
        setOpenInvalidDate(false);
    }

    return (
        <PageBasis>
            <GlobalContainer>
                <MainContainerWithMenu>
                    <SinisterDeclarationHeader>
                        <CustomBackdrop
                            open={openInvalidDate}
                            closeBackdrop={handleInvalidDateDialogClose}
                            message={invalidDateError}
                        />
                        <CustomBackdrop
                            open={openBackdrop}
                            closeBackdrop={handleBackdropClose}
                            message={WAINTING_MESSAGE}
                            isWaiting={true}
                            />
                        {/*<ArrowContainer>*/}
                        {/*    <ArrowSpan>&#8592;</ArrowSpan>*/}
                        {/*</ArrowContainer>*/}
                        <WrapperHeader>
                            <HeaderTitle>Assurer une nouvelle facture</HeaderTitle>
                            <Link to="/basket" style={{color: "black", textDecoration: "none"}}>
                                <IconButton
                                    width="140px"
                                >
                                    Mon panier
                                    <ShoppingCartOutlinedIcon sx={{fontSize: 25}} />
                                </IconButton>
                            </Link>
                        </WrapperHeader>
                    </SinisterDeclarationHeader>
                    <form onSubmit={formik.handleSubmit}>
                        <InformationContainer>
                            <InformationTitle fontSize={24}>Informations concernant la facture à assurer</InformationTitle>
                            <TopFormContainer>
                                <LeftForm>
                                    <WrapperFormItem>
                                        <ProfileLabel>Raison sociale</ProfileLabel>
                                        <CustomTextField
                                            id="companyName"
                                            size="small"
                                            placeholder="Nom de l'entreprise"
                                            onChange={formik.handleChange}
                                            value={formik.values.companyName}
                                            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                            helperText={formik.touched.companyName && formik.errors.companyName}
                                        />
                                    </WrapperFormItem>
                                    <WrapperFormItem>
                                        <ProfileLabel>Numéro Siren</ProfileLabel>
                                        <CustomTextField
                                            id="siren"
                                            size="small"
                                            placeholder="Siren"
                                            inputProps={{ maxLength: 9 }}
                                            onChange={formik.handleChange}
                                            value={formik.values.siren}
                                            error={formik.touched.siren && Boolean(formik.errors.siren)}
                                            helperText={formik.touched.siren && formik.errors.siren}
                                        />
                                    </WrapperFormItem>
                                    <WrapperFormItem>
                                        <ProfileLabel>Montant de la facture</ProfileLabel>
                                        <CustomTextField
                                            id="amount"
                                            size="small"
                                            placeholder="Montant de la facture"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            }}
                                            onChange={formik.handleChange}
                                            value={formik.values.amount}
                                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                                            helperText={formik.touched.amount && formik.errors.amount}
                                        />
                                    </WrapperFormItem>
                                </LeftForm>
                                <RightForm>
                                    <WrapperFormItem>
                                        <ProfileLabel>Date d'émission</ProfileLabel>
                                        <DesktopDatePicker
                                            inputFormat="DD/MM/yyyy"
                                            value={formik.values.issuedDate}
                                            onChange={(value) => formik.setFieldValue("issuedDate", value)}
                                            renderInput={(params) =>
                                                <CustomTextField
                                                    id="issuedDate"
                                                    size="small"
                                                    {...params}
                                                    error={formik.touched.issuedDate && Boolean(formik.errors.issuedDate)}
                                                    helperText={formik.touched.issuedDate && formik.errors.issuedDate}
                                                />}
                                        />
                                    </WrapperFormItem>
                                    <WrapperFormItem>
                                        <ProfileLabel>Date d'échéance</ProfileLabel>
                                        <DesktopDatePicker
                                            id="endDate"
                                            inputFormat="DD/MM/yyyy"
                                            value={formik.values.endDate}
                                            onChange={(value) => formik.setFieldValue("endDate", value)}
                                            renderInput={(params) =>
                                                <CustomTextField
                                                    id="endDate"
                                                    size="small"
                                                    {...params}
                                                    error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                                    helperText={formik.touched.endDate && formik.errors.endDate}
                                                />}
                                        />
                                    </WrapperFormItem>
                                </RightForm>
                            </TopFormContainer>
                        </InformationContainer>
                        <SubmitButtonContainer>
                            <Button type="Submit" width="25%">Ajouter au panier</Button>
                        </SubmitButtonContainer>
                    </form>
                    <EnsureBillDialog
                      open={openDialog}
                      handleCancel={handleCancel}
                      handleValidate={handleValidate}
                      handleAddNewBill={handleAddNewBill}
                      text={dialogMessage}
                      companyName={formik.values.companyName}
                      siren={formik.values.siren}
                      amount={formik.values.amount}
                      duration={billDuration}
                      ensureAmount={ensureAmount}
                    />
                </MainContainerWithMenu>
            </GlobalContainer>
        </PageBasis>
    )
}

export default EnsureBill;
