import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "./common-base.style";

export const Layout1= styled(RowFlexContainer)`
  flex: 1;
  justify-content: space-between;
  width: 75%;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid black;
  box-shadow: 1px 0 0 0 #000000;
  margin-top: ${props=>props.marginVertical};
  margin-bottom: ${props=>props.marginVertical};
`

export const FirstPart= styled(ColumnFlexContainer)`
  flex: 3;
  justify-content: space-between;
`

export const RowWrapper= styled(RowFlexContainer)`
  margin: 10px 0 10px 0;
`

export const SecondPart= styled(ColumnFlexContainer)`
  flex: 3;
  justify-content: space-between;
`

export const ActionSinister= styled(RowFlexContainer)`
  align-items: center;
`
