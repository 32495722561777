import {publicRequest} from "./requests";
import {dateToMonthYear} from "../utils/date-convert";


export const getSinisters = async (dispatch, insuredId) => {
    try {
        const res = await publicRequest.get(`sinisters?filter[include][0][relation]=bill&filter[include][0][scope][where][insuredId]=${insuredId}`)

        const sinistersWithMonth = res.data
            .filter((sinister) => sinister.hasOwnProperty('bill'))
            .map((sinister) => ({
                ...sinister,
                "month": dateToMonthYear(sinister.createdAt)
            }) );
        const sinistersFiltered = sinistersWithMonth.reduce((r, a) => {
            r[a.month] = r[a.month] || [];
            r[a.month].push(a);
            return r;
        }, Object.create(null));
        return {sinistersFiltered};
    } catch (err) {
        console.error(err);
        return {error: "Erreur lors du chargement des sinistres"}
    }
}

export const declareSinister = async (values) => {
    try {
        const {billCopy, ...bodyToSend} = values;

        const resData = await publicRequest.post("sinisters", bodyToSend);
        const body = await resData.data;

        const bodyWithFile= new FormData();
        bodyWithFile.append('files', billCopy);
        bodyWithFile.append('id', body.id);
        const resFile = await publicRequest.post("sinisters/bill", bodyWithFile);
        await resFile.data;
        return {success: "Le sinistre a été créé avec succes"};
    } catch (error) {
        console.error(error);
        return {error: "Erreur lors de la création du sinistre"};
    }
}
