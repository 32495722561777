import {Box, CircularProgress, Dialog} from "@mui/material";
import styled from "styled-components";
import {StandardText} from "./custom-text";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const CustomText = styled(StandardText)`
  text-align: center;
`;

const CustomBackdrop = ({open, closeBackdrop, message, isWaiting}) => {

  const handleClose = () => {
    closeBackdrop();
  }

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
    >
      {isWaiting &&
        <Box sx={{display: 'flex', justifyContent: "center", marginTop: "30px"}}>
          <CircularProgress/>
        </Box>
      }
      <Box sx={{ display: 'flex', margin: '30px' }}>
        <CustomText fontSize={24}>
          {message}
        </CustomText>
      </Box>
      <DialogActions>
        <Box sx={{ display: 'flex' }}>
          <Button onClick={handleClose} variant="outlined">
            <StandardText fontSize={14}>Abandonner</StandardText>
          </Button>
        </Box>
      </DialogActions>
    </StyledDialog>
  );
}

export default CustomBackdrop;
