import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import ListBill from "./pages/ListBill";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Profile from "./pages/Profile";
import SinisterDeclaration from "./pages/SinisterDeclaration";
import EnsureBill from "./pages/EnsureBill";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import Checkout from "./pages/Checkout";
import Login from "./pages/Login";
import EvaluateBill from "./pages/EvaluateBill";
import ListSinister from "./pages/ListSinister";
import Basket from "./pages/Basket";
import ResetPassword from "./pages/ResetPassword";
import UpdatePassword from "./pages/UpdatePassword";
import RequireAuth from "./components/RequireAuth";
import UploadExcelFile from "./pages/UploadExcelFile";


const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        // leezayColor: {
        //     light: COLORS["yellow-l1"],
        //     main: COLORS["blue-l1"],
        //     dark: COLORS["blue-l1"],
        //     contrastText: COLORS["blue-l1"],
        //     // darker: COLORS["blue-l1"],
        // },
        leezayColor: {
            main: "#FF0000",
            light: "#00FF00",
            dark: "#0000FF",
            contrastText: "#FF0000",
            darker: "#0000FF",
        },
    },
});

function App() {

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
              <div className="App">
                  <BrowserRouter>
                      <Routes>
                          {/* public routes */}
                          <Route path="/evaluate-bill" element={<EvaluateBill />}/>
                          <Route path="/faq" element={<FAQ/>}/>
                          <Route path="/contact" element={<Contact/>}/>
                          <Route path="/reset-password" element={<ResetPassword />}/>
                          <Route path="/update-password" element={<UpdatePassword />}/>
                          <Route path="/login" element={<Login />} />

                          {/* protected routes */}
                          <Route element={<RequireAuth allowedRoles={["user", "admin"]} />}>
                              <Route path="/" element={<ListBill/>}/>
                              <Route path="/basket" element={<Basket/>}/>
                              <Route path="/profile" element={<Profile/>}/>
                              <Route path="/sinister-declaration" element={<SinisterDeclaration/>}/>
                              <Route path="/list-sinister" element={<ListSinister/>}/>
                              <Route path="/ensure-bill" element={<EnsureBill/>}/>
                              <Route path="/checkout" element={<Checkout />}/>
                          </Route>

                          {/* admin page */}
                          <Route element={<RequireAuth allowedRoles={["admin"]}/>}>
                              <Route path="/upload-excel-file" element={<UploadExcelFile />}/>
                          </Route>

                          {/*  404 page  */}
                          <Route path="*" element={<Navigate to="/login" replace />}/>
                      </Routes>
                  </BrowserRouter>
              </div>
          </ThemeProvider>
      </LocalizationProvider>
  );
}

export default App;
