import {FirstPart, Layout1, Price, SecondPart, Title} from "./styles/Bill.styled";
import {BigTitle, MediumTitle, StandardText} from "./custom-text";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import {COLORS} from "../utils/colors";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {dateToFrenchFormat, numberOfDaysBetweenTwoDates} from "../utils/date-convert";
import InfoOutlinedIcon from '@mui/icons-material/Info';
import {RowFlexContainer} from "./styles/common-base.style";
import {Tooltip} from "@mui/material";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: COLORS["yellow-l1"],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: COLORS["blue-l1"],
    },
}));

export const Bill = ({bill}) => {

    const [hasSinister, setHasSinister] = useState(false);
    const [canDeclareSinister, setCanDeclareSinister] = useState(true);
    const [expirationDays, setExpirationDays] = useState(0);

    // const canDeclareSinister = function () {
    //     const now = moment()
    //     const b = moment(bill.endDate).format('DD/MM/YYYY')
    //     return now.diff(b, 'days') > 30
    // }

    useEffect(() => {
        if(bill.hasOwnProperty('sinister')) {
            setHasSinister(true);
        } else {
            const now = moment();
            const numberOfDays = numberOfDaysBetweenTwoDates(now, bill.endDate);
            if(numberOfDays < -30) {
                setCanDeclareSinister(false);
                setExpirationDays(-1 * numberOfDays)
            }
        }
    }, [])

    return (
        <Layout1 hasSinister={hasSinister}>
            <FirstPart>
                <MediumTitle color={"#13182B"}>{bill.companyName}</MediumTitle>
                <div>
                    {
                        bill.daysRemaining &&
                        <>
                            <StandardText fontSize={13}>{bill.daysRemaining} jours avant échéance</StandardText>
                            <BorderLinearProgress variant="determinate" value={bill.progression}/>
                        </>
                    }
                    {
                        !bill.daysRemaining &&
                        <>
                            <StandardText fontSize={13}>{dateToFrenchFormat(bill.endDate)}</StandardText>
                            <BorderLinearProgress variant="determinate" value={100}/>
                        </>
                    }
                </div>
            </FirstPart>
            <SecondPart>
                <Price>{bill.amount}€</Price>
                {hasSinister &&
                    <StandardText fontSize={14}>Sinistre déclaré le {dateToFrenchFormat(bill.sinister.createdAt)}</StandardText>
                }
                {(!hasSinister && canDeclareSinister) &&
                    <Link to={`/sinister-declaration`}
                          state={{bill: bill}}
                          style={{color: "#EB2727",}}>
                        Déclarer un sinistre
                    </Link>
                }
                {(!hasSinister && !canDeclareSinister) &&
                    <RowFlexContainer>
                        <StandardText>Expiré il y a {expirationDays} j </StandardText>
                        <Tooltip title="Vous ne pouvez pas déclarer de sinsitre pour les factures expirées il y a plus de 30 jours">
                            <InfoOutlinedIcon/>
                        </Tooltip>
                    </RowFlexContainer>
                }
            </SecondPart>
        </Layout1>
    )
}

Bill.defaultProps = {bill: {amount: 10}}
