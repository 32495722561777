import {ActionSinister, FirstPart, Layout1, RowWrapper, SecondPart} from "./styles/Sinister.styled";
import Link from '@mui/material/Link';
import {MediumTitle, StandardText} from "./custom-text";
import React from "react";
import IconButton from '@mui/material/IconButton';
import {RowFlexContainer} from "./styles/common-base.style";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import {Price} from "./styles/Bill.styled";

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import {dateToFrenchFormat} from "../utils/date-convert";
import {downloadFile} from "../services/file.service";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export const Sinister = ({bill, billFileName, name, phone, marginVertical}) => {

    const downloadBillFile = async () => {
        await downloadFile(billFileName);
    }

    return (
        <Layout1 marginVertical={marginVertical}>
            <FirstPart>
                <RowFlexContainer>
                    <PersonOutlineIcon/>
                    <MediumTitle color={"#13182B"} fontWeight={600}>{name}</MediumTitle>
                </RowFlexContainer>

                <RowWrapper>
                    <PhoneIcon style={{color: "#0652DD"}}/>
                    <MediumTitle color={"#0652DD"} fontWeight={600}>{phone}</MediumTitle>
                </RowWrapper>

                <RowWrapper>
                    <StandardText>Montant : &nbsp; </StandardText>
                    <Price>{bill.amount}€</Price>
                </RowWrapper>

                <ActionSinister>
                    <StandardText color={"#13182B"}>Copie de la facture</StandardText>
                    <IconButton onClick={downloadBillFile} aria-label="add an alarm">
                        <FileDownloadOutlinedIcon sx={{color: "#000000"}}/>
                    </IconButton>
                    <IconButton
                        color="primary"
                        aria-label="add to shopping cart"
                        onClick={(e) => {
                            window.location.href = 'mailto:no-reply@example.com';
                            e.preventDefault();
                        }}
                    >
                        <EmailOutlinedIcon sx={{color: "#000000"}}/>
                    </IconButton>
                </ActionSinister>

            </FirstPart>
            <SecondPart>
                <RowFlexContainer>
                    <BusinessIcon/>
                    <MediumTitle color={"#13182B"} fontWeight={600}>&nbsp;{bill.companyName}</MediumTitle>
                </RowFlexContainer>
                <RowWrapper>
                    <StandardText fontSize={16}>Siren : &nbsp;</StandardText>
                    <Price>{bill.siren}</Price>
                </RowWrapper>
                <RowWrapper>
                    <StandardText fontSize={16}>Date d'émission : &nbsp;</StandardText>
                    <Price>{dateToFrenchFormat(bill.issuedDate)}</Price>
                </RowWrapper>
                <RowWrapper>
                    <StandardText fontSize={16}>Date d'écheance : &nbsp;</StandardText>
                    <Price>{dateToFrenchFormat(bill.endDate)}</Price>
                </RowWrapper>
                <RowWrapper>
                    <StandardText fontSize={16}>Déclaration sinistre : &nbsp;</StandardText>
                    <Price>{dateToFrenchFormat(bill.createdAt)}</Price>
                </RowWrapper>
            </SecondPart>
        </Layout1>
    )
}

Sinister.defaultProps = {nbDay: 16, name: "Jean pierre", phone: "06 98 43 65 30", marginVertical: 0}
