import {publicDownloadFile, publicRequest} from "./requests";
const fileDownload = require('js-file-download');

export const fetchFiles = async () => {
    const res = await publicRequest.get('/files');
    const files = await res.data;
    console.log('Response from list', files);
    //const list = files.map(f => `<li><a href="/files/${f}">${f}</a></li>\n`);
}

export const downloadFile = async (filename) => {
    try {
        const res = await publicDownloadFile.get(`files/${filename}`);
        fileDownload(res.data, filename);
    } catch (err) {
        console.error("Error", err)
    }
}

export const deleteFile = async (filename) => {
    try {
        const res = await publicDownloadFile.delete(`files/${filename}`);
        console.log(res.data);
    } catch (err) {
        console.error("Error", err)
    }
}
