import {Layout1, FirstPart, InformationContainer, Rate, SecondPart} from "./styles/BillBasket.styled";
import Link from '@mui/material/Link';
import {MediumTitle, StandardText} from "./custom-text";
import React from "react";


export const BillBasket = ({siren, nbDay, rate, amount, margin, billId, onRemoveBill}) => {

    const handleRemoveBill = () => {
        onRemoveBill(billId);
    }

    return (
        <Layout1 margin={margin}>
            <FirstPart>
                <MediumTitle fontWeight={700} color={"#13182B"}>{siren}</MediumTitle>
                <InformationContainer>
                    <StandardText fontSize={14}>{amount}€</StandardText>
                    <StandardText fontSize={14}>&nbsp;&nbsp;&nbsp;{nbDay} jours</StandardText>
                </InformationContainer>
            </FirstPart>
            <SecondPart>
                <Rate>{(rate * amount)/100}€</Rate>
                <span style={{marginTop: 10, color:"#EB2727", textDecoration: "underline", cursor: "pointer"}} onClick={handleRemoveBill}>Rétirer du panier</span>
            </SecondPart>
        </Layout1>
    )
}

BillBasket.defaultProps = {siren:"numéro de siren",  nbDay: 16, margin: '10px'}
