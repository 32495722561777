import axios from "axios";

const BASE_URL =  process.env.NODE_ENV ==="production" ? process.env.REACT_APP_API_BASE_URL:"http://localhost:3000/";
const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyNTYxNGY3ZDk1MjE1ZmY0YzBiNzUxNyIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY0OTk1NjQ5NiwiZXhwIjoxNjUwMjE1Njk2fQ.VwmwZ4QbcDTOTfqk8RPs_q17zouKeX5Dg9gIUt4CwNI";


export const publicRequest = axios.create({
    baseURL: BASE_URL
});

export const publicDownloadFile = axios.create({
    baseURL: BASE_URL,
    responseType: "blob",
});

export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: { token : `Bearer ${TOKEN}`}
});
