import {configureStore} from "@reduxjs/toolkit";
import loginReducer from "./login.slice";
import registerReducer from "./register.slice";
import connectedMenuReducer from "./connectedMenu.slice";
import billsReducer from "./bills.slice";
import sinistersReducer from "./sinister.slice";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from "redux-persist/lib/storage"

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const persistedReducer = persistReducer(persistConfig, loginReducer)

export const store = configureStore({
    reducer: {
        register: registerReducer,
        user: persistedReducer,
        connectedMenu: connectedMenuReducer,
        bills: billsReducer,
        sinisters: sinistersReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const persistor = persistStore(store)
