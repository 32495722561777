import {useState} from "react";


const useSnackBar = () => {
  const [fetchStatus, setFetchStatus] = useState(false);
  const [fetchMessage, setFetchMessage] = useState("");
  const [severity, setSeverity] = useState("");

  return {
    fetchStatus, setFetchStatus,
    fetchMessage, setFetchMessage,
    severity, setSeverity
  };
}

export default useSnackBar;
