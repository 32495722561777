import {Header} from "./header";
import {Footer} from "./footer";


export const PageBasis = ({children}) => {
    return (
        <div>
            <Header/>
            {children}
            <Footer/>
        </div>
    )
}
