import styled from "styled-components";
import {RowFlexContainer} from "../components/styles/common-base.style";
import {PageBasis} from "../components/page-basis";
import MainContainerWithMenu from "../components/main-container-with-menu";
import {
    DeleteFile,
    DetailContainer,
    FileNameWrapper,
    ProfileHeader,
    ProfileLabel,
    ValidateButtonContainer,
    WrapperContent,
    WrapperDeleteFile,
    WrapperItem
} from "./styles/Profile.style"
import {BigTitle, StandardText} from "../components/custom-text";
import {IconButton} from "../components/custom-buttons";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import React, {useEffect, useState} from "react";
import {CustomTextField, GlobalContainer, InfoContainer, UploadButton, WrapperFileName} from "./styles/common.style";

import {useFormik} from "formik";
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {profilePage} from "../redux/connectedMenu.slice";
import {
    createInsured,
    deleteBeneficiaryFile,
    deleteCompanyStatusFile,
    deleteIdentityCardFile,
    deleteKbisFile,
    updateInsured,
    updatePerson
} from "../services/user.service";
import {useLocation} from "react-router-dom";
import {downloadFile} from "../services/file.service";
import InfoIcon from "@mui/icons-material/Info";
import {Alert, Tooltip} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

const Profile = ({submitForm}) => {
    const location = useLocation();
    const [update, setUpdate] = useState(location.state.editProfile);
    //const [update, setUpdate] = useState(true);
    const [canDeleteKbisFile, setCanDeleteKbisFile] = useState(false)
    const [canDeleteCompanyStatusFile, setCanDeleteCompanyStatusFile] = useState(false);
    const [canDeleteBeneficiaryFile, setCanDeleteBeneficiaryFile] = useState(false);
    const [canDeleteIdentityCardFile, setCanDeleteIdentityCardFile] = useState(false);
    
    // Sanckbar
    const [SBOpen, setSBOpen] = useState(false);
    const [SBMessage, setSBMessage] = useState("");
    const [SBSeverity, setSBSeverity] = useState("success");

    const dispatch = useDispatch();

    const {currentUser} = useSelector(state => state.user)
    useEffect(()=> {
        dispatch(profilePage());
        if(currentUser?.insured?.kbisOriginalFileName !== null &&
            currentUser?.insured?.kbisOriginalFileName !== undefined &&
            currentUser?.insured?.kbisOriginalFileName !== "") {
            setCanDeleteKbisFile(true);
        }
        if(currentUser?.insured?.companyStatusOriginalFileName !== null &&
            currentUser?.insured?.companyStatusOriginalFileName !== undefined &&
            currentUser?.insured?.companyStatusOriginalFileName !== "") {
            setCanDeleteCompanyStatusFile(true);
        }
        if(currentUser?.insured?.beneficiaryOriginalFileName !== null &&
            currentUser?.insured?.beneficiaryOriginalFileName !== undefined &&
            currentUser?.insured?.beneficiaryOriginalFileName !== "") {
            setCanDeleteBeneficiaryFile(true);
        }
        if(currentUser?.insured?.identityCardOriginalFileName !== null &&
            currentUser?.insured?.identityCardOriginalFileName !== undefined &&
            currentUser?.insured?.identityCardOriginalFileName !== "") {
            setCanDeleteIdentityCardFile(true);
        }
    }, [currentUser])

    const handleClick = () => {
        setUpdate(!update);
    }

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .required('Obligatoire'),
        lastName: Yup.string()
            .required('Obligatoire'),
        email: Yup.string()
            .email('Entrez un mail valid')
            .required('Obligatoire'),
        phone: Yup.number()
            .typeError('Veuillez saisir un nombre')
            .required('Obligatoire'),
        siren: Yup.number()
            .typeError('Veuillez saisir un nombre'),
        postalCode: Yup.number()
            .typeError('Veuillez saisir un nombre'),
    });

    const formik = useFormik({
        initialValues: {
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            phone: currentUser?.phone,
            email: currentUser?.email,
            companyName: currentUser?.insured?.companyName,
            siren: currentUser?.insured?.siren,
            address: currentUser?.insured?.address,
            postalCode: currentUser?.insured?.postalCode,
            city: currentUser?.insured?.city,
            country: currentUser?.insured?.country,
            identityCardFile: {name:  currentUser?.insured?.identityCardOriginalFileName},
            kbisFile: {name:  currentUser?.insured?.kbisOriginalFileName},
            beneficiaryFile: {name:  currentUser?.insured?.beneficiaryOriginalFileName},
            companyStatusFile: {name:  currentUser?.insured?.companyStatusOriginalFileName},
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await handleSubmit(values);
        }
    });
    
    const handleSubmit = async (values) => {
        const {firstName, lastName, phone, email, identityCardFile,
            companyName, siren, address, postalCode, city, country, kbisFile, beneficiaryFile, companyStatusFile} = values;
        await updatePerson(dispatch, {firstName, lastName, phone, email}, currentUser)
        if(currentUser.hasOwnProperty('insured')) {
            const {success} = await updateInsured(dispatch,
              {companyName, siren, address, postalCode, city, country, kbisFile, beneficiaryFile, companyStatusFile, identityCardFile},
              currentUser)
            handleSubmitStatus(success);
        } else {
            const {success} = await createInsured(dispatch, {companyName, siren, address, postalCode, city, country, kbisFile, beneficiaryFile, companyStatusFile, identityCardFile}, currentUser);
            handleSubmitStatus(success);
        }
    }
    
    const handleSubmitStatus = (success) => {
        handleClick();
        console.log("success", success)
        if (success) {
            handleSubmitSuccess();
        } else {
            handleSubmitFail();
        }
    }
    
    const handleSubmitSuccess = () => {
        if (currentUser?.insured?.isProfileCompleted) {
            setSBMessage("Votre profil a été compléter avec succes, vous pouvez dès à présent assurer une facture");
        } else {
            setSBMessage("Votre profil a été mis à jour avec succes");
        }
        setSBSeverity("success")
        setSBOpen(true);
    }
    
    const handleSubmitFail = () => {
        setSBMessage("Le profile n'a pas pu être mis à jour");
        setSBSeverity("error")
        setSBOpen(true);
    }

    const download = async () => {
        if(currentUser?.insured?.kbisOriginalFileName){
            await downloadFile(currentUser.insured.kbisFileName);
        }
    }

    const deleteKbis = async () => {
        await handleDelete(deleteKbisFile, "kbisFile")
        setCanDeleteKbisFile(false)
    }

    const deleteCompanyStatus = async () => {
        await handleDelete(deleteCompanyStatusFile, "companyStatusFile")
        setCanDeleteCompanyStatusFile(false)
    }

    const deleteBeneficiary = async () => {
        await handleDelete(deleteBeneficiaryFile, "beneficiaryFile")
        setCanDeleteBeneficiaryFile(false)
    }

    const deleteIdentityCard = async () => {
        await handleDelete(deleteIdentityCardFile, "identityCardFile")
        setCanDeleteIdentityCardFile(false)
    }

    const handleDelete = async (callback, fieldName) => {
        if(currentUser?.insured?.id){
            await callback(dispatch, currentUser.insured.id, currentUser.email);
            formik.setFieldValue(fieldName, {name: ""});
        }
    }
    
    const handleSBClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSBOpen(false);
    }

    return (
        <PageBasis>
            <GlobalContainer>
                <MainContainerWithMenu>
                    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={SBOpen} autoHideDuration={3000} onClose={handleSBClose}>
                        <Alert onClose={handleSBClose} severity={SBSeverity} variant="filled" sx={{ width: '100%' }}>
                            {SBMessage}
                        </Alert>
                    </Snackbar>
                        <ProfileHeader>
                            <BigTitle>Mon espace</BigTitle>
                            {update &&
                                <IconButton

                                    width="180px"
                                    type="submit"
                                    form="profile-form"
                                    onClick={submitForm}
                                >
                                    Valider mon profil
                                    <DoneOutlinedIcon sx={{fontSize: 25}}/>
                                </IconButton>
                            }
                            { !update &&
                              <>
                                <IconButton
                                    id="edit"
                                    width="180px"
                                    type="button"
                                    onClick={handleClick}
                                >
                                    Editer mon profil
                                    <ModeEditOutlineOutlinedIcon sx={{fontSize: 25}}/>
                                </IconButton>
                              </>
                            }
                        </ProfileHeader>
                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                        <DetailContainer>
                            <StandardText fontSize={24}>Mes informations personnelles</StandardText>
                            <WrapperContent>
                                {update ?
                                    <div>
                                        <WrapperItem>
                                            <ProfileLabel>Prénom</ProfileLabel>
                                            <CustomTextField
                                                id="firstName"
                                                size="small"
                                                disabled={true}
                                                placeholder="Prénom"
                                                style={{width: "60%"}}
                                                onChange={formik.handleChange}
                                                value={formik.values.firstName}
                                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                                helperText={formik.touched.firstName && formik.errors.firstName}
                                            />
                                        </WrapperItem>
                                        <WrapperItem>
                                            <ProfileLabel>Nom</ProfileLabel>
                                            <CustomTextField
                                                id="lastName"
                                                size="small"
                                                disabled={true}
                                                placeholder="Nom"
                                                style={{width: "60%"}}
                                                onChange={formik.handleChange}
                                                value={formik.values.lastName}
                                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                                helperText={formik.touched.lastName && formik.errors.lastName}
                                            />
                                        </WrapperItem>
                                    </div> :
                                    <BigTitle>{currentUser.firstName} {currentUser.lastName}</BigTitle>
                                }
                                <WrapperItem>
                                    <ProfileLabel>Numéro de téléphone</ProfileLabel>
                                    {update ?
                                        <CustomTextField
                                            id="phone"
                                            size="small"
                                            placeholder="Numéro de téléphone"
                                            style={{width: "60%"}}
                                            onChange={formik.handleChange}
                                            value={formik.values.phone}
                                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                                            helperText={formik.touched.phone && formik.errors.phone}
                                        /> :
                                        <StandardText fontSize={18}>{currentUser.phone}</StandardText>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <ProfileLabel>Adresse mail</ProfileLabel>
                                    {update ?
                                        <CustomTextField
                                            id="email"
                                            type="email"
                                            size="small"
                                            placeholder="Mail"
                                            style={{width: "60%"}}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        /> :
                                        <StandardText fontSize={18}>{currentUser.email}</StandardText>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <ProfileLabel>Carte d'identité</ProfileLabel>
                                    {update ?
                                        <RowFlexContainer>
                                            {
                                                !canDeleteIdentityCardFile &&
                                                <UploadButton width="20%">
                                                    Ajouter
                                                    <input
                                                        id="identityCardFile"
                                                        name="identityCardFile"
                                                        type="file"
                                                        onChange={(e) =>
                                                            formik.setFieldValue('identityCardFile', e.currentTarget.files[0])
                                                        }
                                                        style={{display: "none"}}
                                                    />
                                                    <FileDownloadOutlinedIcon sx={{fontSize: 25}}/>
                                                </UploadButton>
                                            }
                                            <WrapperFileName>
                                                <StandardText fontSize={18}>{formik.values.identityCardFile.name}</StandardText>
                                            </WrapperFileName>
                                            {
                                                canDeleteIdentityCardFile &&
                                                <WrapperDeleteFile onClick={deleteIdentityCard}>
                                                    <DeleteFile fontSize={16}>Supprimer</DeleteFile>
                                                </WrapperDeleteFile>
                                            }
                                        </RowFlexContainer>
                                         :
                                        <FileNameWrapper fontSize={18} italic={!formik.values.identityCardFile.name}>
                                            {formik.values.identityCardFile.name ? formik.values.identityCardFile.name : "Non renseigné"}
                                        </FileNameWrapper>
                                    }
                                </WrapperItem>
                            </WrapperContent>
                        </DetailContainer>
                        <DetailContainer>
                            <StandardText fontSize={24}>Mon entreprise</StandardText>
                            <WrapperContent>
                                <WrapperItem>
                                    <ProfileLabel>Raison sociale</ProfileLabel>
                                    {update ?
                                        <CustomTextField
                                            id="companyName"
                                            type="text"
                                            size="small"
                                            placeholder="Raison social"
                                            style={{width: "60%"}}
                                            onChange={formik.handleChange}
                                            value={formik.values.companyName}
                                            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                            helperText={formik.touched.companyName && formik.errors.companyName}
                                        /> :
                                        <StandardText fontSize={18}>{currentUser?.insured?.companyName}</StandardText>
                                    }
                                </WrapperItem>

                                <WrapperItem>
                                    <ProfileLabel>Siren</ProfileLabel>
                                    {update ?
                                        <CustomTextField
                                            id="siren"
                                            type="text"
                                            size="small"
                                            placeholder="Siren"
                                            style={{width: "60%"}}
                                            onChange={formik.handleChange}
                                            value={formik.values.siren}
                                            error={formik.touched.siren && Boolean(formik.errors.siren)}
                                            helperText={formik.touched.siren && formik.errors.siren}
                                        /> :
                                        <StandardText fontSize={18}>{currentUser?.insured?.siren}</StandardText>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <ProfileLabel>Adresse</ProfileLabel>
                                    {update ?
                                        <CustomTextField
                                            id="address"
                                            type="text"
                                            size="small"
                                            placeholder="Adresse"
                                            style={{width: "60%"}}
                                            onChange={formik.handleChange}
                                            value={formik.values.address}
                                            error={formik.touched.address && Boolean(formik.errors.address)}
                                            helperText={formik.touched.address && formik.errors.address}
                                        /> :
                                        <StandardText fontSize={18}>{currentUser?.insured?.address}</StandardText>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <ProfileLabel>Code postal</ProfileLabel>
                                    {update ?
                                        <CustomTextField
                                            id="postalCode"
                                            type="text"
                                            size="small"
                                            placeholder="Code postal"
                                            style={{width: "60%"}}
                                            onChange={formik.handleChange}
                                            value={formik.values.postalCode}
                                            error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                                            helperText={formik.touched.postalCode && formik.errors.postalCode}
                                        /> :
                                        <StandardText fontSize={18}>{currentUser?.insured?.postalCode}</StandardText>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <ProfileLabel>Ville</ProfileLabel>
                                    {update ?
                                        <CustomTextField
                                            id="city"
                                            type="text"
                                            size="small"
                                            placeholder="Ville"
                                            style={{width: "60%"}}
                                            onChange={formik.handleChange}
                                            value={formik.values.city}
                                            error={formik.touched.city && Boolean(formik.errors.city)}
                                            helperText={formik.touched.city && formik.errors.city}
                                        /> :
                                        <StandardText fontSize={18}>{currentUser?.insured?.city}</StandardText>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <ProfileLabel>Pays</ProfileLabel>
                                    {update ?
                                        <CustomTextField
                                            id="country"
                                            type="text"
                                            size="small"
                                            placeholder="Pays"
                                            style={{width: "60%"}}
                                            onChange={formik.handleChange}
                                            value={formik.values.country}
                                            error={formik.touched.country && Boolean(formik.errors.country)}
                                            helperText={formik.touched.country && formik.errors.country}
                                        /> :
                                        <StandardText fontSize={18}>{currentUser?.insured?.country}</StandardText>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <ProfileLabel>Extrait Kbis</ProfileLabel>
                                    {update ?
                                        <RowFlexContainer>
                                            {
                                                !canDeleteKbisFile &&
                                                <UploadButton width="20%" >
                                                    Ajouter
                                                    <input id="kbisFile"
                                                           name="kbisFile"
                                                           type="file"
                                                           onChange={(e) =>
                                                               formik.setFieldValue('kbisFile', e.currentTarget.files[0])
                                                           }
                                                           style={{display: "none"}} />

                                                    <FileUploadOutlinedIcon sx={{fontSize: 25}} />
                                                </UploadButton>
                                            }

                                            <WrapperFileName onClick={download}>
                                                <StandardText fontSize={18}>{formik.values.kbisFile.name}</StandardText>
                                            </WrapperFileName>
                                            {
                                                canDeleteKbisFile &&
                                                <WrapperDeleteFile onClick={deleteKbis}>
                                                    <DeleteFile fontSize={16}>Supprimer</DeleteFile>
                                                </WrapperDeleteFile>
                                            }

                                        </RowFlexContainer> :
                                        <FileNameWrapper fontSize={18} italic={!formik.values.kbisFile.name}>
                                            {formik.values.kbisFile.name ? formik.values.kbisFile.name : "Non renseigné"}
                                        </FileNameWrapper>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <ProfileLabel>Statuts de l'entreprise</ProfileLabel>
                                    {update ?
                                        <RowFlexContainer>
                                            {
                                                !canDeleteCompanyStatusFile &&
                                                <UploadButton width="20%">
                                                    Ajouter
                                                    <input
                                                        id="companyStatusFile"
                                                        name="companyStatusFile"
                                                        type="file"
                                                        onChange={(e) =>
                                                            formik.setFieldValue('companyStatusFile', e.currentTarget.files[0])
                                                        }
                                                        style={{display: "none"}}/>
                                                    <FileUploadOutlinedIcon sx={{fontSize: 25}} />
                                                </UploadButton>
                                            }
                                            <WrapperFileName>
                                                <StandardText fontSize={18}>{formik.values.companyStatusFile.name}</StandardText>
                                            </WrapperFileName>
                                            {
                                                canDeleteCompanyStatusFile &&
                                                <WrapperDeleteFile onClick={deleteCompanyStatus}>
                                                    <DeleteFile fontSize={16}>Supprimer</DeleteFile>
                                                </WrapperDeleteFile>
                                            }
                                        </RowFlexContainer> :
                                        <FileNameWrapper fontSize={18} italic={!formik.values.companyStatusFile.name}>
                                            {formik.values.companyStatusFile.name ? formik.values.companyStatusFile.name : "Non renseigné"}
                                        </FileNameWrapper>
                                    }
                                </WrapperItem>
                                <WrapperItem>
                                    <RowFlexContainer>
                                        <ProfileLabel>Déclaration de bénéficiaire effectif</ProfileLabel>
                                        <Tooltip arrow placement="top" title="Définition de la déclaration de bénéficiaire effectif">
                                            <InfoContainer>
                                                <InfoIcon color="action"/>
                                            </InfoContainer>
                                        </Tooltip>
                                    </RowFlexContainer>
                                    {update ?
                                        <RowFlexContainer>
                                            {!canDeleteBeneficiaryFile &&
                                                <UploadButton width="20%" >
                                                    Ajouter
                                                    <input id="beneficiaryFile"
                                                           name="beneficiaryFile"
                                                           type="file"
                                                           onChange={(e) =>
                                                               formik.setFieldValue("beneficiaryFile", e.currentTarget.files[0])
                                                           }
                                                           style={{display: "none"}} />
                                                    <FileUploadOutlinedIcon sx={{fontSize: 25}} />
                                                </UploadButton>
                                            }
                                            <WrapperFileName>
                                                <StandardText fontSize={18}>{formik.values.beneficiaryFile.name}</StandardText>
                                            </WrapperFileName>
                                            {
                                                canDeleteBeneficiaryFile &&
                                                <WrapperDeleteFile onClick={deleteBeneficiary}>
                                                    <DeleteFile fontSize={16}>Supprimer</DeleteFile>
                                                </WrapperDeleteFile>
                                            }
                                        </RowFlexContainer> :
                                        <FileNameWrapper fontSize={18} italic={!formik.values.beneficiaryFile.name}>
                                            {formik.values.beneficiaryFile.name ? formik.values.beneficiaryFile.name : "Non renseigné"}
                                        </FileNameWrapper>
                                    }
                                </WrapperItem>
                            </WrapperContent>
                        </DetailContainer>
                        <ValidateButtonContainer>
                            {update &&
                                <IconButton
                                    width="180px"
                                    type="submit"
                                    form="profile-form"
                                >
                                    Valider mon profil
                                    <DoneOutlinedIcon sx={{fontSize: 25}}/>
                                </IconButton>
                            }
                        </ValidateButtonContainer>

                    </form>
                </MainContainerWithMenu>
            </GlobalContainer>
        </PageBasis>
    );
}

export default Profile;
