import styled from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "./styles/common-base.style";
import {CardIconText} from "./card-icon-text";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import Thunderstorm from "@mui/icons-material/Thunderstorm";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import React from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {billPage, profilePage, sinisterPage} from "../redux/connectedMenu.slice";


const MainContainer = styled(RowFlexContainer)`
  flex: 1;
  justify-content: center;
  width: 60%;
  align-self: center;
`
const Container = styled(ColumnFlexContainer)`
  height: 375px;
  justify-content: space-between;
  flex: 1;
  align-items: center;
    background-color: #FBFDFF;
`

const MenuItemWrapper = styled.div`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`

const RightContainer = styled(ColumnFlexContainer)`
  flex: 4;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 2px 2px 0 black;
    background-color: white;
`

const MainContainerWithMenu = ({children}) => {

    const dispatch = useDispatch();
    const { isBill, isSinister, isProfile } = useSelector(state => state.connectedMenu);

    return (
        <MainContainer>
            <Container>
                <MenuItemWrapper>
                    <Link to={'/'} style={{textDecoration: "none"}}>
                        <CardIconText
                            width={110}
                            height={110}
                            icon={<Inventory2OutlinedIcon sx={{fontSize: 40, color: isBill?"#F4FC00":"#0652DD"}}/>}
                            text="Factures"
                            textColor= {isBill?"#F4FC00":"#0652DD" }
                            color="000000"
                            bgColor={isBill?"0652DD":"FFFFFF"}
                            onClick={()=>dispatch(billPage())}
                        />
                    </Link>
                </MenuItemWrapper>
                <MenuItemWrapper>
                    <Link to={'/list-sinister'} style={{textDecoration: "none"}}>
                        <CardIconText
                            width={110}
                            height={110}
                            icon={<Thunderstorm sx={{fontSize: 40, color: isSinister?"#F4FC00":"#0652DD"}}/>}
                            text="Sinistres"
                            textColor= {isSinister?"#F4FC00":"#0652DD" }
                            color="000000"
                            bgColor={isSinister?"0652DD":"FFFFFF"}
                            onClick={()=>dispatch(sinisterPage())}
                        />
                    </Link>
                </MenuItemWrapper>
                <MenuItemWrapper>
                    <Link to={'/profile'} state={{ editProfile: false }} style={{textDecoration: "none"}}>
                        <CardIconText
                            width={110}
                            height={110}
                            icon={<PersonOutlineOutlinedIcon sx={{fontSize: 40, color: isProfile?"#F4FC00":"#0652DD"}}/>}
                            text="Mon espace"
                            textColor= {isProfile?"#F4FC00":"#0652DD" }
                            color="000000"
                            bgColor={isProfile?"0652DD":"FFFFFF"}
                            onClick={()=>dispatch(profilePage())}
                        />
                    </Link>
                </MenuItemWrapper>
            </Container>
            <RightContainer>
                {children}
            </RightContainer>
        </MainContainer>
    )
}

export default MainContainerWithMenu;
