import styled, { keyframes } from "styled-components";
import {ColumnFlexContainer, RowFlexContainer} from "../../components/styles/common-base.style";
import {MediumTitle, VeryBigTitle} from "../../components/custom-text";

export const BaseContainer = styled(ColumnFlexContainer)`
  align-self: center;
  width: 60%;
  height: 50%;
  padding-top: 5%;
`

export const PageTitle = styled(VeryBigTitle)`
  margin: 0 0 30px 0;
`

export const DescriptionText = styled.span`
  font-size: 18px;
  margin-bottom: 80px;
  margin-left: 20%;
`

// Contact Page : form part

export const WrapperForm = styled(ColumnFlexContainer)`
  width: 80%;
  align-self: flex-end;
`

export const FormContainer = styled(RowFlexContainer)`
`

export const FormLeftContainer = styled(ColumnFlexContainer)`
  width: 50%;
  //border: solid 2px yellow;
`

export const FormRightContainer = styled(ColumnFlexContainer)`
  width: 50%;
  //border: solid 2px green;
`

export const InputLabel = styled(MediumTitle)`
  margin-bottom: 10px;
`

export const WrappedFormItem = styled(ColumnFlexContainer)`
  width: 90%;
  margin-bottom: 20px;
  //border: solid 2px grey;
`
export const FormButtonContainer = styled(RowFlexContainer)`
  width: 90%;
  margin: 0 0 25px 0;
  justify-content: center;
`

export const WrapperTitle = styled(ColumnFlexContainer)`
  height: 120px;
  margin-bottom: 50px;
`

export const WrapperQuestions = styled(ColumnFlexContainer)`
  height: 350px;
`

export const QuestionsContainer = styled(ColumnFlexContainer)`
  justify-content: space-around;
  align-self: flex-end;
  width: 80%;
  margin-top: 2%;
  height: 250px;
`

export const WrapperTheme = styled(ColumnFlexContainer)`
  height: 120px;
`

export const ThemeContainer = styled(RowFlexContainer)`
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
`

export const ThemeButton = styled(ColumnFlexContainer)`
  width: 180px;
  height: 50px;
  border-radius: 5px;
  border: solid 2px black;
  box-shadow: 5px 5px 0 0 #F4FC00;
  justify-content: center;
  align-items: center;
`

export const ButtonTheme = styled.span`
  font-size: 20px;
  font-weight: 500;
`

export const RepeatedText = styled.div`
  max-width: 100%;
  height: 30px;
  border-top: solid 2px #0652DD;
  border-bottom: solid 2px #0652DD;
  overflow: hidden;
  margin: 10px 0 10px 0;
`

const translate = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`

export const MotionContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: ${translate} 20s linear infinite;
  justify-content: center;
  align-items: center;
`

export const TextScroll = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #0652DD;
  display: inline-block;
  margin: 0 20px 0 20px;
`

export const ContactContainer = styled(ColumnFlexContainer)`
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 200px;
  align-self: center;
`
export const WrapperContactText = styled(ColumnFlexContainer)`
  margin: 20px;
  justify-content: center;
  align-items: center;
`

export const TextCustom = styled.span`
  margin: 20px;
`
