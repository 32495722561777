import {createSlice} from "@reduxjs/toolkit";


const sinistersSlice = createSlice({
    name: "sinisters",
    initialState: {
        isFetching: false,
        success: false,
        error: false,
        sinisters: [],
    },
    reducers: {
        sinistersFetchStart: (state) => {
            state.isFetching = true;
            state.error = false;
            state.success = false;
        },
        sinistersFetchSuccess: (state,{payload}) => {
            state.isFetching = false;
            state.success = true;
            state.error = false;
            state.sinisters = payload.sinisters
        },
        sinistersFetchFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.success = false;
        },
        sinistersReset: (state) => {
            state.isFetching = false;
            state.success = true;
            state.error = false;
            state.sinisters = [];
        },
    }
})

export const { sinistersFetchStart, sinistersFetchSuccess, sinistersFetchFailure, sinistersReset } = sinistersSlice.actions;
export default sinistersSlice.reducer;
