import {loginFail, loginStart, loginSuccess, updateUserSuccess} from "../redux/login.slice";
import {publicRequest} from "./requests";

const updateUser = async (dispatch, email) => {
    try {
        const resUser = await publicRequest.get(`person?filter[where][email]=${email}&filter[include][][relation]=insured`);
        dispatch(updateUserSuccess(resUser.data[0]));
    } catch (err) {
        console.error(err)
    }
}

export const resetPassword = async (email) => {
    try {
        const response = await publicRequest.post(`/reset-password/init`, {email});
        return {success: response.data}
    } catch (error) {
        console.error("Error", error);
        return {error: error.response.data.error.message}
    }
}

export const updatePassword = async ({resetKey, password, confirmPassword}) => {
    try {
        const response = await publicRequest.put(`/reset-password/finish`, {resetKey, password, confirmPassword});
        return {success: response.data}
    } catch (error) {
        console.error(error);
        return {error: error.response.data.error.message}
    }
}

export const updatePerson = async (dispatch, {firstName, lastName, phone, email, identityCard}, person) => {
    try {
        const resPerson = await publicRequest.put(`person/${person.id}`, {firstName, lastName, phone, email});
        await updateUser(dispatch, email);
    }catch (e) {
        console.error("Error", e)
    }
}

export const createInsured = async (dispatch, {companyName, siren, address, postalCode, city, country,
    kbisFile, beneficiaryFile, companyStatusFile, identityCardFile}, person) => {
    try {
        let isProfileCompleted = false;
        if(companyName!=="" &&
            siren!=="" &&
            address!=="" &&
            postalCode!=="" &&
            city!=="" &&
            country!=="" &&
            typeof kbisFile.name !== 'undefined' &&
            kbisFile.name !== "" &&
            typeof beneficiaryFile.name !== 'undefined' &&
            beneficiaryFile.name !== "" &&
            typeof companyStatusFile.name !== 'undefined' &&
            companyStatusFile.name !== "" &&
            typeof identityCardFile.name !== 'undefined' &&
            identityCardFile.name !== ""
        ) {
            isProfileCompleted = true;
        }
        const resInsured = await publicRequest.post(`insureds`,
            {companyName, siren, address, postalCode, city, country, isProfileCompleted, "personId": person.id});

        if(typeof kbisFile.name !== 'undefined' && kbisFile.name !== "") {
            const id = await resInsured.data.id;
            const insured = await uploadFile(kbisFile, "insureds/kbis", id)
        }
        if(typeof companyStatusFile.name !== 'undefined' && companyStatusFile.name !== "" ) {
            const id = await resInsured.data.id;
            const insuredResult = await uploadFile(companyStatusFile, "insureds/beneficiary", id)
        }
        if(typeof beneficiaryFile.name !== 'undefined' && beneficiaryFile.name !== "") {
            const id = await resInsured.data.id;
            const insuredResult = await uploadFile(beneficiaryFile, "insureds/company-status", id)
        }
        if(typeof identityCardFile.name !== 'undefined' && identityCardFile.name !== "") {
            const id = await resInsured.data.id;
            const insuredResult = await uploadFile(identityCardFile, "insureds/identity-card", id)
        }
        await updateUser(dispatch, person.email)
        return {success: true}
    }catch (e) {
        console.error("Error", e)
        return {success: false}
    }
}

export const updateInsured = async (dispatch, {companyName, siren, address, postalCode, city, country,
    kbisFile, beneficiaryFile, companyStatusFile, identityCardFile}, person) => {
    console.log("Rentréé")
    const {insured} = person;
    let isProfileCompleted = false;
    if(companyName!=="" &&
        siren!=="" &&
        address!=="" &&
        postalCode!=="" &&
        city!=="" &&
        country!=="" &&
        typeof kbisFile.name !== 'undefined' &&
        kbisFile.name !== "" &&
        typeof beneficiaryFile.name !== 'undefined' &&
        beneficiaryFile.name !== "" &&
        typeof companyStatusFile.name !== 'undefined' &&
        companyStatusFile.name !== "" &&
        typeof identityCardFile.name !== 'undefined' &&
        identityCardFile.name !== ""
    ) {
        isProfileCompleted = true;
    }
    try {
        const resInsured = await publicRequest.put(`insureds/${insured.id}`,
            {companyName, siren, address, postalCode, city, country, isProfileCompleted, "personId": insured.personId,
                "kbisOriginalFileName": insured.kbisOriginalFileName ? insured.kbisOriginalFileName : "",
                "kbisFileName": insured.kbisFileName ? insured.kbisFileName : "",
                "companyStatusOriginalFileName": insured.companyStatusOriginalFileName ? insured.companyStatusOriginalFileName : "",
                "companyStatusFileName": insured.companyStatusFileName ? insured.companyStatusFileName : "",
                "beneficiaryOriginalFileName": insured.beneficiaryOriginalFileName ? insured.beneficiaryOriginalFileName : "",
                "beneficiaryFileName": insured.beneficiaryFileName ? insured.beneficiaryFileName : "",
                "identityCardOriginalFileName": insured.identityCardOriginalFileName ? insured.identityCardOriginalFileName : "",
                "identityCardFileName": insured.identityCardFileName ? insured.identityCardFileName : "",
            });
        
        if(typeof kbisFile.name !== 'undefined' &&
            kbisFile.name !== "" &&
            insured.kbisOriginalFileName !== kbisFile.name) {
            const insuredResult = await uploadFile(kbisFile, "insureds/kbis", insured.id)
        }
        
        if(typeof identityCardFile.name !== 'undefined' &&
            identityCardFile.name !== "" &&
            insured.identityCardOriginalFileName !== identityCardFile.name) {
            const personFile = await uploadFile(identityCardFile, "insureds/identity-card", insured.id)
        }

        if(typeof companyStatusFile.name !== 'undefined' &&
            companyStatusFile.name !== "" &&
            insured.companyStatusOriginalFileName !== companyStatusFile.name) {
            const insuredResult = await uploadFile(companyStatusFile, "insureds/company-status", insured.id)
        }
        if(typeof beneficiaryFile.name !== 'undefined' &&
            beneficiaryFile.name !== "" &&
            insured.beneficiaryOriginalFileName !== beneficiaryFile.name) {
            const insuredResult = await uploadFile(beneficiaryFile, "insureds/beneficiary", insured.id)
        }
        await updateUser(dispatch, person.email)
        return {success: true}
    } catch (error) {
        console.error("Error", error.message)
        return {success: false}
    }
}

export const deleteIdentityCardFile = async (dispatch, insuredId, email) => {
    const response = await publicRequest.put(`insureds/${insuredId}/identity-card`)
    await updateUser(dispatch, email);
}

export const deleteKbisFile = async (dispatch, insuredId, email) => {
    const response = await publicRequest.put(`insureds/${insuredId}/kbis`)
    await updateUser(dispatch, email);
}

export const deleteCompanyStatusFile = async (dispatch, insuredId, email) => {
    const response = await publicRequest.put(`insureds/${insuredId}/company-status`)
    await updateUser(dispatch, email);
}

export const deleteBeneficiaryFile = async (dispatch, insuredId, email) => {
    const response = await publicRequest.put(`insureds/${insuredId}/beneficiary`)
    await updateUser(dispatch, email);
}

export const uploadFile = async (file, fileUrl, id) => {

    const bodyWithFile= new FormData();
    bodyWithFile.append('files', file);
    bodyWithFile.append('id',id);

    const resFile = await publicRequest.post(fileUrl, bodyWithFile);
    return await resFile.data;
}
